/* || TYPOGRAPHY */
.t-h0 {
  @include typography(headingZero);
}

.t-h1 {
  @include typography(headingOne);
}

.t-h2 {
  @include typography(headingTwo);
}

.t-h3 {
  @include typography(headingThree);

  &-h1 {
    @include typography(headingThree);
    @include media(screen1024) {
      @include typography(headingOne);
    }
  }

  &-h2 {
    @include typography(headingThree);
    @include media(screen1024) {
      @include typography(headingTwo);
    }
  }
}

.t-h4 {
  @include typography(headingFour);
}

.t-h4-h3 {
  @include typography(headingFour);
  @include media(screen1024) {
    @include typography(headingThree);
  }
}

.t-h5 {
  @include typography(headingFive);
}

.t-h5-h4 {
  @include typography(headingFive);
  @include media(screen1024) {
    @include typography(headingFour);
  }
}

.t-copy-huge {
  @include typography(copyHuge);
}

.t-copy-big {
  @include typography(copyBig);
}

.t-copy-small {
  @include typography(copySmall);
}

.t-copy-tiny {
  @include typography(copyTiny);
}

.t-copy-small-tiny {
  @include typography(copySmall);
  @include media(screen1024) {
    @include typography(copyTiny);
  }
}

.t-copy-tiny-small {
  @include typography(copyTiny);
  @include media(screen1024) {
    @include typography(copySmall);
  }
}

.t-notes {
  @include typography(notes);
}

.t-lists {
  @include typography(lists);
}

/* tagsBig and tagsSmall are only different on WILDE. All tag classes look identical on STAYCITY */
.t-tags-big {
  @include typography(tagsBig);
  font-family: $font-family-tags;
}

.t-tags-small {
  @include typography(tagsSmall);
  font-family: $font-family-tags;
}

.blog-sub-heading {
  font-family: $font-family-tags;
}

/* Some places on WILDE use different tags sizes between mobile and desktop */
.t-tags-big-small {
  @include typography(tagsBig);
  font-family: $font-family-tags;
  @include media(screen1024) {
    @include typography(tagsSmall);
  }
}

.t-tags-small-big {
  @include typography(tagsSmall);
  font-family: $font-family-tags;
  @include media(screen1024) {
    @include typography(tagsBig);
  }
}

.t-tags-caps {
  @include typography(tagsCaps);
}

.t-pill {
  @include typography(pill);
}

/* || LISTS */
.ul {
  &--error-page {
    list-style: none;

    li {
      align-items: flex-start;
      gap: convert-to-rem(5);
      padding: convert-to-rem(6);
      &::before {
        content: "";
        mask-repeat: no-repeat;
        mask-size: contain;
        mask-position: center;
        background-color: var(--text-color);
        width: convert-to-rem(18);
        height: convert-to-rem(20);

        @include media(screen1024) {
          width: convert-to-rem(30);
          height: convert-to-rem(35);
        }
      }
    }
    li:nth-child(3n + 1) {
      &::before {
        mask-image: url("../assets/icons/asterisk6.svg");
      }
    }
    li:nth-child(3n + 2) {
      &::before {
        mask-image: url("../assets/icons/asterisk7.svg");
      }
    }
    li:nth-child(3n + 3) {
      &::before {
        mask-image: url("../assets/icons/asterisk2.svg");
      }
    }
  }
}

/* || RICH TEXT AREAS */

/* Spacing between paragraphs within rich text sections. P tags cannot be targeted directly as they are generated within the CMS */
.rich-text {
  & > *:not(:first-child) {
    margin-top: 1rem;
  }
}

.rich-text.t-tags-big-small *,
.rich-text.t-tags-big * {
  font-family: $font-family-tags;
}

.rich-text-svg {
  & svg {
    width: 100%;
    height: 100%;
  }
  &--auto {
    & svg {
      width: auto;
      height: 100%;
    }
  }
}

.rich-text ul,
.rich-text ol {
  @include typography(lists);
}

.rich-text a {
  @extend .cta;
  @extend .cta--sm;
}

/* Blog articles have their own specific rich text styles */
.rich-text-blog {
  /* Text has an extra level of indentation, doesn't apply to images */
  & p,
  & h2,
  & h3,
  & h4,
  & ul,
  & ol,
  & .tall-imgs {
    margin-left: 0;
    margin-right: 0;
    @include media(screen1024) {
      margin-left: 19vw;
      margin-right: 19vw;
    }
  }

  & em {
    @include typography($blog-italic);
    font-family: $font-family-tags;
  }

  & .blog-img {
    margin-top: convert-to-rem(60);
    @include media(screen1024) {
      margin-top: convert-to-rem(70);
    }

    & + p .blog-copy {
      margin-top: convert-to-rem(60);
      @include media(screen1024) {
        margin-top: convert-to-rem(70);
      }
    }
  }

  & .blog-heading {
    @include typography($blogHeading);
    display: inline-block;
    margin-top: convert-to-rem(60);
    @include media(screen1024) {
      margin-top: convert-to-rem(70);
    }
  }
  & .blog-sub-heading {
    @include typography($blogSubHeading);
    display: inline-block;
    margin-top: convert-to-rem(25);
  }
  & .blog-copy {
    @include typography(copySmall);
    display: inline-block;
    margin-top: convert-to-rem(23);
    @include media(screen1024) {
      margin-top: convert-to-rem(15);
    }
  }
  & .blog-copy-big {
    @include typography(copyHuge);
    display: inline-block;
    margin-top: convert-to-rem(23);
    @include media(screen1024) {
      margin-top: convert-to-rem(15);
    }
  }
  & ul {
    @include typography(copySmall);
    display: inline-block;
    margin-top: convert-to-rem(23);
    list-style: none;
    @include media(screen1024) {
      margin-top: convert-to-rem(10);
    }

    & li {
      margin-top: convert-to-rem(5);

      & .blog-copy {
        margin: 0;
      }

      &::before {
        content: "";
        mask-image: $blog-ul-mask;
        mask-repeat: no-repeat;
        mask-size: contain;
        mask-position: center;
        background-color: var(--blog-ul-icon-color);
        width: $ul-icon-width;
        height: $ul-icon-height;
      }
    }
  }
  & ol {
    @include typography(copySmall);
    display: inline-block;
    margin-top: convert-to-rem(23);
    list-style: none;
    counter-reset: item;
    @include media(screen1024) {
      margin-top: convert-to-rem(10);
    }

    & li {
      margin-top: convert-to-rem(5);
      counter-increment: item;

      & .blog-copy {
        margin: 0;
      }

      &::before {
        content: counter(item);
        color: var(--blog-ul-icon-color);
        @include typography(copySmall);
      }
    }
  }

  & > figure {
    width: 100%;
    margin-inline: auto;
    margin-top: convert-to-rem(60);
    @include media(screen1024) {
      width: max-content;
      margin-top: convert-to-rem(70);
    }
    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  & figcaption {
    @include typography(copyTiny);
    opacity: $blog-figcaption-opacity;
  }
  & > iframe {
    min-width: unset !important;
    margin-inline: auto !important;
    margin-top: convert-to-rem(60) !important;
    margin-bottom: 0 !important;
    @include media(screen1024) {
      margin-top: convert-to-rem(70) !important;
    }

    & + p .blog-copy {
      margin-top: convert-to-rem(60);
      @include media(screen1024) {
        margin-top: convert-to-rem(70);
      }
    }
  }
}

/* Preview of blog article, don't show images and left-align all text */
.rich-text-blog-listing {
  & figure {
    display: none;
  }
  & p {
    /* !important is needed to overwrite inline styles which are set in the cms rich text field */
    text-align: left !important;
  }
}

/* || ADDITIONAL DECORATIONS */
.decorate {
  &--none {
    text-decoration: none;
  }
  &--inherit {
    text-decoration: inherit;
  }
  &--underline {
    text-decoration: underline;
  }
}

/* || TEXT ALIGN */
.t-align {
  &--left {
    text-align: left;
  }
  &--center {
    text-align: center;
  }
  &--right {
    text-align: right;
  }
  &--center-left {
    text-align: center;
    @include media(screen1024) {
      text-align: left;
    }
  }
  &--left-right {
    text-align: left;
    @include media(screen1024) {
      text-align: right;
    }
  }
  &--icon-info {
    text-align: $icon-info-align;
  }
}

.strong {
  font-weight: 600;
}

.color {
  &--current-color {
    color: currentColor;
  }
  &--secondary-text {
    color: var(--btn-secondary-text-color);
  }
}

.hyphens {
  &--auto {
    hyphens: auto;
  }
}

.line-height {
  &--0 {
    line-height: 0;
  }
  &--1 {
    line-height: 1rem;
  }
  &--1-5 {
    line-height: 1.5rem;
  }
  &--2 {
    line-height: 2rem;
  }
  &--100 {
    line-height: 100%;
  }
  &--initial {
    line-height: initial;
  }
}

.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &--3 {
    -webkit-line-clamp: 3;
  }
  &--5 {
    -webkit-line-clamp: 5;
  }
}

/* || TEXT TRANSFORM */
.t-transform {
  &--lowercase {
    text-transform: lowercase;
  }

  &--uppercase {
    text-transform: uppercase;
  }
}

.font-size {
  &--inherit {
    font-size: inherit;
  }
}

.line-height {
  &--inherit {
    line-height: inherit;
  }
}

.letter-spacing {
  &--inherit {
    letter-spacing: inherit;
  }
}

.letter-spacing--0-18 {
  letter-spacing: 0.18px;
}

.disney-registered-mark {
  sup {
    line-height: 0.8;
    position: relative;
    top: convert-to-rem(4);
    @include media(screen1024) {
      top: convert-to-rem(5);
    }
  }
  &.t-h1 {
    sup {
      top: convert-to-rem(12);
      @include media(screen1024) {
        top: convert-to-rem(22);
      }
    }
  }
  &.t-h3,
  &.t-h4,
  &.t-h4-h3 {
    sup {
      top: convert-to-rem(10);
      @include media(screen1024) {
        top: convert-to-rem(9);
      }
    }
  }
}

.t-copy-single-image-with-intro-text {
  font-weight: 400;
  font-size: convert-to-rem(22);
  line-height: convert-to-rem(33);
  @include media(screen1024) {
    font-size: convert-to-rem(28);
    line-height: convert-to-rem(40);
  }
}
