/* || MARGINS */
.margin {
  &--desk-50 {
    &--b {
      @include media(screen1024) {
        margin-bottom: convert-to-rem(50);
      }
    }
    &--t {
      @include media(screen1024) {
        margin-top: convert-to-rem(50);
      }
    }
  }
  &--mobile-30 {
    &--b {
      @include media(screen0, $direction: max) {
        margin-bottom: convert-to-rem(30);
      }
    }
    &--t {
      @include media(screen0, $direction: max) {
        margin-top: convert-to-rem(30);
      }
    }
  }
  &-35 {
    &--l {
      margin-left: convert-to-rem(35);
    }
  }
  &-auto {
    &--x {
      margin-left: auto;
      margin-right: auto;
    }
  }
  &-20 {
    margin: convert-to-rem(20);
    &--t {
      margin-top: convert-to-rem(20);
    }
    &--r {
      margin-right: convert-to-rem(20);
    }
    &--b {
      margin-bottom: convert-to-rem(20);
    }
    &--l {
      margin-left: convert-to-rem(20);
    }
  }
  &-13 {
    &--b {
      margin-bottom: convert-to-rem(13);
    }
  }
  &-xs {
    &--t {
      margin-top: 0.25rem;
    }
    &--b {
      margin-bottom: 0.25rem;
    }
    &--l {
      margin-left: 0.25rem;
    }
    &--r {
      margin-right: 0.25rem;
    }
  }
  &-sm {
    margin: 0.5rem;

    &--t {
      margin-top: 0.5rem;
    }
    &--b {
      margin-bottom: 0.5rem;
    }
    &--l {
      margin-left: 0.5rem;
    }
    &--r {
      margin-right: 0.5rem;
    }
    &--x {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
    &--y {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    &-0 {
      &--t {
        margin-top: 0.5rem;
        @include media(screen1024) {
          margin-top: 0;
        }
      }
    }
  }
  &-md {
    margin: 1rem;

    &--t-neg {
      margin-top: -1rem;
    }
    &--t {
      margin-top: 1rem;
      &--mobile-only {
        margin-top: 1rem;
        @include media(screen1024) {
          margin-top: 0;
        }
      }
    }
    &--b {
      margin-bottom: 1rem;
    }
    &--l {
      margin-left: 1rem;
    }
    &--r {
      margin-right: 1rem;
    }
    &--x {
      margin-left: 1rem;
      margin-right: 1rem;
    }
    &--y {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
  &-lg {
    margin: 1.5rem;

    &--t {
      margin-top: 1.5rem;
    }
    &--t-minus {
      margin-top: -1.5rem;
    }
    &--b {
      margin-bottom: 1.5rem;
    }
    &--l {
      margin-left: 1.5rem;
    }
    &--y {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
  &-xl {
    margin: 1.75rem;

    &--l {
      margin-left: 1.75rem;
    }

    &--t {
      margin-top: 1.75rem;
    }

    &--b {
      margin-bottom: 1.75rem;
    }

    &--y {
      margin-top: 1.75rem;
      margin-bottom: 1.75rem;
    }
  }
  &-xxl {
    &--l {
      margin-left: 2rem;
    }

    &--b {
      margin-bottom: 2rem;
    }
  }
  &-20 {
    &--b {
      margin-bottom: convert-to-rem(20);
    }
  }
  &-25 {
    &--b {
      margin-bottom: convert-to-rem(25);
    }
  }
  &-sm-md {
    &--l {
      margin-left: 0.5rem;
      @include media(screen1024) {
        margin-left: 1rem;
      }
    }
  }
  &-md-0 {
    &--t {
      margin-top: 1rem;
      @include media(screen1024) {
        margin-top: 0;
      }
    }
  }
  &-lg-0 {
    &--b {
      margin-bottom: 1.5rem;
      @include media(screen1024) {
        margin-bottom: 0;
      }
    }

    &--t {
      margin-top: 1.5rem;
      @include media(screen1024) {
        margin-top: 0;
      }
    }
  }
  &-0-lg {
    &--t {
      margin-top: 0;
      @include media(screen0) {
        margin-top: 1.5rem;
      }
    }
  }
  &--1024-0 {
    &--x {
      @include media(screen1024) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  &--icon-info {
    margin-bottom: convert-to-rem(15);
    @include media(screen0) {
      margin-bottom: convert-to-rem(20);
    }
  }
  &--nav-links {
    margin-top: convert-to-rem(20);
  }
  &--error-title {
    margin-left: convert-to-rem(20);
  }
  &--nav-links-first:first-of-type {
    margin-top: convert-to-rem(20);
  }
  &--nav-hr {
    margin-top: convert-to-rem(30);
    margin-bottom: convert-to-rem(25);
    @include media(screen0) {
      margin-bottom: convert-to-rem(30);
    }
  }
  &--section-header {
    margin-bottom: convert-to-rem(25);
    @include media(screen0) {
      margin-bottom: convert-to-rem(45);
    }
  }
  &--section-header-lg {
    margin-bottom: convert-to-rem(40);
    @include media(screen0) {
      margin-bottom: convert-to-rem(82);
    }
  }
  &--single-img-header {
    margin-top: convert-to-rem(88);
    @include media(screen0) {
      margin-top: convert-to-rem(139);
    }
  }
  &--img-card {
    margin-bottom: convert-to-rem(10);
    @include media(screen0) {
      margin-bottom: convert-to-rem(23);
    }
  }
  &--prop-facilities {
    margin-top: convert-to-rem(50);
    @include media(screen1024) {
      margin-top: convert-to-rem(15);
    }
  }
  &--facilities-cta {
    margin-top: convert-to-rem(35);
  }
  &--footer-links {
    margin-top: convert-to-rem(20);
    @include media(screen1024) {
      margin-top: convert-to-rem(25);
    }
  }
  &--map-marker-label {
    margin-top: $map-label-margin;
  }
  &--gallery-desktop {
    margin-bottom: convert-to-rem(66);
  }
  &--feature-grid-cta {
    margin-top: convert-to-rem(40);
    @include media(screen1024) {
      margin-top: convert-to-rem(73);
    }
  }
  &--guest-services-ctas {
    margin-top: convert-to-rem(30);
    @include media(screen1024) {
      margin-top: convert-to-rem(88);
    }
  }
  &--guest-services-sub-title {
    margin-top: convert-to-rem(46);
    @include media(screen1024) {
      margin-top: convert-to-rem(35);
    }
  }
  &--reviews {
    margin-top: convert-to-rem(54);
  }
  &--reviews-overlay-title {
    margin-top: convert-to-rem(30);
    @include media(screen1024) {
      margin-top: 0;
    }
  }
  &--reviews-overlay-content {
    margin-top: convert-to-rem(34);
    @include media(screen1024) {
      margin-top: convert-to-rem(45);
    }
  }
  &--reviews-overlay-rating {
    margin-top: convert-to-rem(25);
    margin-bottom: $margin-reviews-overlay-rating-b-mob;
    @include media(screen1024) {
      margin-top: convert-to-rem(25);
      margin-bottom: $margin-reviews-overlay-rating-b-desk;
    }
  }
  &--blog-listing-title {
    margin-top: convert-to-rem(5);
    @include media(screen1024) {
      margin-top: convert-to-rem(10);
    }
  }
  &--blog-listing-content {
    margin-top: convert-to-rem(15);
    @include media(screen1024) {
      margin-top: convert-to-rem(20);
    }
  }
  &--blog-listing-show-more {
    margin-top: convert-to-rem(79);
    @include media(screen1024) {
      margin-top: convert-to-rem(100);
    }
  }
  &--city-header-text {
    margin-top: 0;
    @include media(screen1024) {
      margin-top: convert-to-rem(30);
    }
  }
  &--city-header-mobile-img {
    margin-top: convert-to-rem(68);
  }
  &--error-cta {
    margin-top: convert-to-rem(30);
    @include media(screen1024) {
      margin-top: convert-to-rem(23);
    }
  }
  &--my-account-carousel {
    margin-top: convert-to-rem(40);
    margin-bottom: convert-to-rem(53);
    @include media(screen1024) {
      margin-top: convert-to-rem(20);
      margin-bottom: convert-to-rem(30);
    }
  }
  &--my-account-carousel-items {
    margin-top: convert-to-rem(20);
    @include media(screen1024) {
      margin-top: convert-to-rem(30);
    }
  }
  &-8vw-desk {
    &--l {
      @include media(screen1024) {
        margin-left: 8vw;
      }
    }
    &--r {
      @include media(screen1024) {
        margin-right: 8vw;
      }
    }
  }
  &-12vw-desk {
    &--l {
      @include media(screen1024) {
        margin-left: 12vw;
      }
    }
    &--r {
      @include media(screen1024) {
        margin-right: 12vw;
      }
    }
  }
  &-5vw-mobile {
    &--l {
      @include media(screen1024, $direction: max) {
        margin-left: 5vw;
      }
    }
    &--r {
      @include media(screen1024, $direction: max) {
        margin-right: 5vw;
      }
    }
  }
  &--desk-21 {
    &--t {
      @include media(screen1024) {
        margin-top: convert-to-rem(21);
      }
    }
  }
  &--desk-44 {
    &--b {
      @include media(screen1024) {
        margin-bottom: convert-to-rem(44);
      }
    }
  }
  &--desk-42 {
    &--b {
      @include media(screen1024) {
        margin-bottom: convert-to-rem(42);
      }
    }
  }
  &--13 {
    &--b {
      margin-bottom: convert-to-rem(13);
    }
  }
}

/* || UNIVERSAL SPACING BETWEEN COMPONENTS */
.umb-block-grid__area .component-spacer + .component-spacer {
  margin-top: convert-to-rem(86);
  @include media(screen0) {
    margin-top: convert-to-rem(146);
  }
}

/* || BORDERS */
.border {
  @include border;

  &--none {
    border: none;
  }
  &--round {
    border-radius: $border-radius;

    &-xs {
      border-radius: $border-radius-xs;
    }
    &-sm {
      border-radius: $border-radius-sm;
    }
    &-lg {
      border-radius: $border-radius-lg;
    }
    &-full {
      border-radius: 50%;
    }

    &-top {
      border-radius: $border-radius-top $border-radius-top 0 0;

      &-left {
        border-radius: $border-radius-top 0 0 0;

        &--md {
          @include media(screen1024) {
            border-radius: $border-radius-top 0 0 0;
          }
        }
      }

      &-right {
        border-radius: 0 $border-radius-top 0 0;
      }
    }

    &-right {
      border-radius: 0 $border-radius-right $border-radius-right 0;
    }

    &-bottom {
      border-radius: 0 0 $border-radius-bottom $border-radius-bottom;
    }

    &-left {
      border-radius: $border-radius-left 0 0 $border-radius-left;
    }
    &-bottom-left-desktop {
      border-radius: 0;
      @include media(screen1024) {
        border-radius: 0 0 0 $border-radius;
      }
    }
    &-bottom-right-desktop {
      border-radius: 0;
      @include media(screen1024) {
        border-radius: 0 0 $border-radius 0;
      }
    }
  }
  &--square-round {
    border-radius: 0;
    @include media(screen0) {
      border-radius: $border-radius;
    }
  }
  &--square-round-1024 {
    border-radius: 0;
    @include media(screen1024) {
      border-radius: $border-radius;
    }
  }
  &--top {
    @include border(border-top);
  }
  &--bottom {
    @include border(border-bottom);
  }
  &--bottom-not-last:not(:last-of-type) {
    @include border(border-bottom);
  }
  &--left {
    @include border(border-left);
  }
  &--full {
    border: convert-to-rem(1) solid var(--text-color);
  }
  &--top-left {
    @include border(border-top);
    @include media(screen1024) {
      border-top: 0;
      @include border(border-left);
    }
  }
  &--desktop {
    border: none;
    @include media(screen1024) {
      @include border;
    }
  }
  &--bottom-mobile {
    @include border(border-bottom);
    @include media(screen1024) {
      border: none;
    }
  }
  &--top-bottom-mobile {
    @include border(border-top);
    @include border(border-bottom);
    @include media(screen1024) {
      border: none;
    }
  }
  &--bottom-desktop {
    border: none;
    @include media(screen1024) {
      @include border(border-bottom);
    }
  }
  &--left-desktop {
    border: none;
    @include media(screen1024) {
      @include border(border-left);
    }
  }
  &--slim-promo-card:not(:last-of-type) {
    &::after {
      content: "";
      width: convert-to-rem(1);
      height: 60%;
      background-color: rgba(var(--text-color-rgb), 0.15);
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &--feature-grid {
    /* All grid items get a border-left */
    & > div {
      @include feature-grid-border(border-left);

      /* All grid items also get a border top. Needs to be pseudo as it is not a full width border */
      @include feature-grid-border-top;
    }
    /* Every second item gets a border right on mobile */
    & > :nth-child(even) {
      @include feature-grid-border(border-right);
    }
    /* Last two items also get a pseudo border bottom */
    & > :nth-last-of-type(-n + 2) {
      @include feature-grid-border-bottom;
    }

    @include media(screen1024) {
      /* Remove mobile border right on desktop */
      & > div:not(:nth-child(5n + 5)) {
        border-right: none;
      }
      /* Every fifth grid item gets a border right */
      & > :nth-child(5n + 5) {
        @include feature-grid-border(border-right);
      }
      /* Last 5 grid items get a pseudo border bottom */
      & > :nth-last-of-type(-n + 5) {
        @include feature-grid-border-bottom;
      }
    }
  }
}

/* || PADDINGS */
.padding {
  &-35 {
    &--l {
      padding-left: convert-to-rem(35);
    }
  }
  &-xxs {
    padding: convert-to-rem(2);
    &--y {
      padding-top: convert-to-rem(2);
      padding-bottom: convert-to-rem(2);
    }
  }
  &-xs {
    padding: 0.25rem;
    &--y {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }
  }
  &-sm {
    padding: 0.5rem;
    &--b {
      padding-bottom: 0.5rem;
    }
    &--x {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
    &--l {
      padding-left: 0.5rem;
    }
    &--r {
      padding-right: 0.5rem;
    }
  }
  &-md {
    padding: 1rem;

    &--x {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    &--y {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    &--b {
      padding-bottom: 1rem;
    }
    &--t {
      padding-top: 1rem;
    }
    &--l {
      padding-left: 1rem;
    }
    &--r {
      padding-right: 1rem;
    }
  }
  &-lg {
    padding: 1.5rem;

    &--x {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    &--y {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }

    &--b {
      padding-bottom: 1.5rem;
    }

    &--l {
      padding-left: 1.5rem;
    }

    &--t {
      padding-top: 1.5rem;
    }
    &--r {
      padding-right: 1.5rem;
    }
  }
  &-xl {
    padding: 1.75rem;

    &--x {
      padding-left: 1.75rem;
      padding-right: 1.75rem;
    }

    &--y {
      padding-top: 1.75rem;
      padding-bottom: 1.75rem;
    }

    &--l {
      padding-left: 1.75rem;
    }

    &--r {
      padding-right: 1.75rem;
    }

    &--t {
      padding-top: 1.75rem;
    }

    &--b {
      padding-bottom: 1.75rem;
    }
    &--r {
      padding-right: 1.75rem;
    }
  }
  &-xxl {
    padding: 2rem;

    &--b {
      padding-bottom: 2rem;
    }
  }
  &-2lg {
    padding: 3rem;
  }
  &-20 {
    padding: convert-to-rem(20);
    &--t {
      padding-top: convert-to-rem(20);
    }
    &--r {
      padding-right: convert-to-rem(20);
    }
    &--b {
      padding-bottom: convert-to-rem(20);
    }
    &--l {
      padding-left: convert-to-rem(20);
    }
  }
  &-13 {
    &--b {
      padding-bottom: convert-to-rem(13);
    }
  }
  &-25 {
    &--b {
      padding-bottom: convert-to-rem(25);
    }
  }
  &-lg-2lg {
    padding: 1.5rem;
    @include media(screen1024) {
      padding: 3rem;
    }
  }
  &-lg-0 {
    padding: 1.5rem;
    @include media(screen1024) {
      padding: 0;
    }
    &--t {
      padding-top: 1.5rem;
      @include media(screen1024) {
        padding-top: 0;
      }
    }
  }
  &--220-l-desk {
    @include media(screen1024) {
      padding-left: convert-to-rem(220);
    }
  }
  &--27-desk {
    &--t {
      @include media(screen1024) {
        padding-top: convert-to-rem(27);
      }
    }
  }
  &--35-desk {
    &--b {
      @include media(screen1024) {
        padding-bottom: convert-to-rem(35);
      }
    }
  }
  &-0-md {
    &--x {
      padding-left: 0;
      padding-right: 0;
      @include media(screen1024) {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
    &--b {
      padding-bottom: 0;
      @include media(screen1024) {
        padding-bottom: 1rem;
      }
    }
  }
  &-20-0 {
    &--x {
      padding-left: convert-to-rem(20);
      padding-right: convert-to-rem(20);
      @include media(screen1024) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  &-xs-0 {
    &--b {
      padding-bottom: 0.25rem;
      @include media(screen1024) {
        padding-bottom: 0;
      }
    }
  }
  &-sm-0 {
    &--y {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      @include media(screen1024) {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    &--b {
      padding-bottom: 0.5rem;
      @include media(screen1024) {
        padding-bottom: 0;
      }
    }
  }
  &-md-0 {
    &--y {
      padding-top: 1rem;
      padding-bottom: 1rem;
      @include media(screen1024) {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    &--b {
      padding-bottom: 1rem;
      @include media(screen1024) {
        padding-bottom: 0;
      }
    }
  }
  &-xl-xxl {
    padding: 1.75rem;

    @include media(screen1024) {
      padding: 2rem;
    }
  }
  &--1024-0 {
    @include media(screen1024) {
      padding: 0;
    }
  }
  &--header {
    padding-top: convert-to-rem(66);
    @include media(screen1024) {
      padding-top: convert-to-rem(112);
    }

    &-desktop {
      @include media(screen1024) {
        padding-top: convert-to-rem(112);
      }
    }

    &-mobile {
      padding-top: convert-to-rem(66);
      @include media(screen1024) {
        padding-top: inherit;
      }
    }
  }
  &--header-img {
    padding: 0;
    @include media(screen1024) {
      padding: convert-to-rem(50);
    }
  }

  &--desk-50 {
    @include media(screen1024) {
      padding: convert-to-rem(50);
    }
  }

  &--city-header {
    padding-top: $padding-city-header-mob;
    padding-bottom: 0;
    @include media(screen1024) {
      padding-top: $padding-city-header-desk;
      padding-bottom: 2rem;
    }
  }
  &--city-header-border {
    padding-bottom: 0;
    @include media(screen1024) {
      padding-bottom: convert-to-rem(50);
    }
  }
  &--city-properties {
    padding-bottom: 0;
    @include media(screen1024) {
      padding-bottom: $padding-city-properties;
    }
  }
  &--guest-services-header {
    padding-top: $padding-guest-services-header-mob;
    padding-bottom: convert-to-rem(41);
    @include media(screen1024) {
      padding-top: $padding-guest-services-header-desk;
      padding-bottom: convert-to-rem(60);
    }
  }
  &--blog-listing-header {
    padding-bottom: convert-to-rem(120);
    @include media(screen1024) {
      padding-bottom: convert-to-rem(160);
    }
  }
  &--nav {
    padding: convert-to-rem(20) 0 convert-to-rem(70);
    @include media(screen0) {
      padding: convert-to-rem(27) 0 convert-to-rem(62);
    }
  }
  &--nav-right {
    padding-top: convert-to-rem(30);
    padding-left: 0;
    @include media(screen1024) {
      padding-top: 0;
      padding-left: convert-to-rem(60);
    }
  }
  &-link-grid-item {
    padding: 1rem;
    @include media(screen0) {
      padding: 1.5rem;
    }
  }
  &-promo-card-item {
    padding: convert-to-rem(26);
    @include media(screen0) {
      padding: convert-to-rem(35);
    }
  }
  &--city-intro {
    padding-top: convert-to-rem(24);
    padding-bottom: convert-to-rem(40);
    @include media(screen0) {
      padding-bottom: convert-to-rem(75);
    }
  }
  &--city-section {
    padding-top: convert-to-rem(26);
    padding-bottom: convert-to-rem(40);
    @include media(screen0) {
      padding-top: convert-to-rem(48);
      padding-bottom: convert-to-rem(75);
    }
  }
  &--hotel-carousel {
    padding-right: 0;
    @include media(screen0) {
      padding-right: convert-to-rem(73);
    }
  }
  &--footer-logo {
    padding: 1.25rem 0.5rem;
    @include media(screen0) {
      padding: 1.5rem 0.5rem;
    }
  }
  &--gallery-close-btn {
    padding: 0.5rem;
    @include media(screen0) {
      padding: 0.75rem;
    }
  }
  &--gallery-modal-header {
    padding: convert-to-rem(40) 0 convert-to-rem(30);
    @include media(screen0) {
      padding: convert-to-rem(60) 0 convert-to-rem(50);
    }
  }
  &--gallery-modal-images {
    padding-bottom: convert-to-rem(54);
    @include media(screen0) {
      padding-bottom: convert-to-rem(182);
    }
  }
  &--slim-promo-card {
    padding-left: convert-to-rem(20);
    padding-right: convert-to-rem(20);
    @include media(screen1024) {
      padding-left: convert-to-rem(42);
      padding-right: convert-to-rem(42);

      &:first-of-type {
        padding-left: 0;
      }
      &:last-of-type {
        padding-right: 0;
      }
    }
  }
  &--anchor-link {
    padding-left: $padding-anchor-link-mob;
    @include media(screen0) {
      padding-left: $padding-anchor-link-desk;
    }
  }
  &--feature-grid-text {
    padding: convert-to-rem(21) convert-to-rem(16);
    @include media(screen0) {
      padding: convert-to-rem(34);
    }
  }
  &--feature-grid-img {
    padding: convert-to-rem(10);
    @include media(screen0) {
      padding: convert-to-rem(16);
    }
  }
  &--guest-services-cta {
    padding: $padding-guest-services-cta-mob;
    @include media(screen0) {
      padding: $padding-guest-services-cta-desk;
    }
  }
  &--guest-services-footer {
    padding-top: convert-to-rem(40);
    padding-bottom: convert-to-rem(190);
    @include media(screen0) {
      padding-top: convert-to-rem(96);
      padding-bottom: convert-to-rem(118);
    }
  }
  &--reviews-overlay {
    padding-top: convert-to-rem(19);
    padding-bottom: convert-to-rem(37);
    @include media(screen0) {
      padding-top: convert-to-rem(40);
      padding-bottom: convert-to-rem(40);
    }
  }
  &--reviews-overlay-review:not(:last-of-type) {
    padding-bottom: convert-to-rem(25);
  }
  &--blog-article-header {
    padding-bottom: convert-to-rem(30);
  }
  &--blog-article {
    padding-bottom: convert-to-rem(82);
    @include media(screen1024) {
      padding-bottom: convert-to-rem(152);
    }
  }
  &--blog-listing {
    padding-bottom: convert-to-rem(94);
    @include media(screen1024) {
      padding-bottom: 0;
    }
  }
  &--error-page {
    padding-top: convert-to-rem(120);
    padding-bottom: convert-to-rem(97);
    @include media(screen1024) {
      padding-top: convert-to-rem(137);
      padding-bottom: convert-to-rem(125);
    }
  }
  &--account-dropdown {
    padding: convert-to-rem(20) convert-to-rem(25);
  }
  &--my-account-sub-nav {
    padding-top: convert-to-rem(28);
    @include media(screen1024) {
      padding-top: convert-to-rem(32);
    }
  }
  &--desk-l-80 {
    @include media(screen1024) {
      padding-left: convert-to-rem(80);
    }
  }
  &--desk-r-80 {
    @include media(screen1024) {
      padding-right: convert-to-rem(80);
    }
  }
  &-0 {
    padding: 0;
    &--desk {
      &--l {
        @include media(screen1024) {
          padding-left: 0;
        }
      }
    }
  }
}

/* || MARGIN / PADDING MIXTURE */
/* Universal spacing for block grid elements */
.block-spacing {
  &--x {
    margin-left: 5vw;
    margin-right: 5vw;

    @include media(screen0) {
      margin-right: 4vw;
      margin-left: 4vw;
    }

    &-mobile {
      margin-left: 5vw;
      margin-right: 5vw;

      @include media(screen0) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    &-desktop {
      margin-left: 0;
      margin-right: 0;

      @include media(screen0) {
        margin-right: 4vw;
        margin-left: 4vw;
      }
    }

    &-pd-mg {
      padding-left: 5vw;
      padding-right: 5vw;

      @include media(screen0) {
        padding-left: 0;
        padding-right: 0;

        margin-right: 4vw;
        margin-left: 4vw;
      }
    }
  }

  &--y {
    padding-top: $block-spacing-y-mob;
    padding-bottom: $block-spacing-y-mob;

    @include media(screen0) {
      padding-top: convert-to-rem(146);
      padding-bottom: convert-to-rem(146);
    }

    &-sm {
      padding-top: $block-spacing-y-mob;
      padding-bottom: $block-spacing-y-mob;
      @include media(screen0) {
        padding-top: convert-to-rem(67);
        padding-bottom: convert-to-rem(67);
      }
    }
    &-md {
      padding-top: $block-spacing-y-mob;
      padding-bottom: $block-spacing-y-mob;
      @include media(screen0) {
        padding-top: convert-to-rem(111);
        padding-bottom: convert-to-rem(111);
      }
    }
  }

  /* Header comes from block grid, but should not have the top padding */
  &--header {
    &-x {
      margin-left: -5vw;
      margin-right: -5vw;
      @include media(screen0) {
        margin-left: -4vw;
        margin-right: -4vw;
      }
    }
    &-y {
      margin-top: convert-to-rem(-46);
      @include media(screen0) {
        margin-top: convert-to-rem(-146);
      }
    }
  }

  &-inner {
    &--m {
      margin-left: 0;
      margin-right: 0;

      @include media(screen0) {
        margin-left: 10vw;
        margin-right: 10vw;
      }
    }
    &--x {
      margin-left: 0;
      margin-right: 0;

      @include media(screen0) {
        margin-left: 8vw;
        margin-right: 8vw;
      }
    }
    &--l {
      margin-left: 0;
      margin-right: 0;

      @include media(screen0) {
        margin-left: 8vw;
      }
    }
    /* Goes to edge of screen on the right. (Hotel carousel) */
    &--hotel-carousel {
      margin-left: 0;
      margin-right: -5vw;

      @include media(screen0) {
        margin-left: 8vw;
        margin-right: -4vw;
      }
    }
  }
}
