.rest {
  &--5 {
    background-color: rgba(var(--text-color-rgb), 0.05);
  }
  &--white-3 {
    background-color: rgba($c-white, 0.3);
  }
}

.hover {
  @media (hover: hover) and (pointer: fine) {
    &--fade {
      &:hover {
        opacity: 0.7;
      }
      &-4 {
        &:hover {
          opacity: 0.4;
        }
      }
      &-6 {
        &:hover {
          opacity: 0.6;
        }
      }
    }
    &--darken-cta {
      &:hover {
        & .darken-cta {
          color: $c-white;
          background-color: $c-black;
          opacity: 1;
        }
      }
    }
    &--slim-promo-card {
      &:hover {
        & .slim-promo-fade {
          opacity: 0.6;
        }
        & .darken-cta {
          color: $c-white;
          background-color: $c-black;
          opacity: 1;
        }
      }
    }
    &--underline {
      &:hover {
        text-decoration: underline;
      }
    }
    &--rise {
      &:hover {
        transform: translateY(convert-to-rem(-10));
      }
    }
    &--color {
      &:hover {
        color: var(--btn-primary-text-color);
      }
    }
    &--fill {
      &-5 {
        &:hover {
          background-color: rgba(var(--text-color-rgb), 0.05);
        }
      }
      &-30 {
        &:hover {
          background-color: rgba(var(--text-color-rgb), 0.3);
        }
      }
      &-text-color {
        &:hover {
          background-color: var(--btn-primary-text-color);
          border-color: var(--btn-primary-text-color);
        }
      }
      &-guest-services-cta {
        &:hover {
          background-color: var(--guest-services-cta-h);
        }
      }
      &-reviews-pill:hover {
        background-color: var(--c-alert-positive);

        & svg {
          color: var(--c-alert-positive-h);
        }
      }
      &-promo-card-cta {
        &:hover {
          background-color: var(--c-promo-card-cta-h);
          border-color: var(--c-promo-card-cta-h);
          color: var(--c-promo-card-cta-text-h);
        }
      }
      &-account-dropdown {
        &:hover {
          background-color: var(--account-dropdown-h);
          color:var(--account-dropdown-text-h);
        }
      }
    }
    &--darken {
      &:hover {
        filter: brightness(85%);
      }
    }
  }
}

.active {
  @media (hover: hover) and (pointer: fine) {
    &--fade {
      &:active {
        opacity: 0.7;
      }
      &-4 {
        &:active {
          opacity: 0.4;
        }
      }
      &-2 {
        &:active {
          opacity: 0.2;
        }
      }
    }

    &--fill {
      &-5 {
        &:active {
          background-color: rgba(var(--text-color-rgb), 0.05);
          border-color: rgba(var(--text-color-rgb), 0.05);
        }
      }
      &-10 {
        &:active {
          background-color: rgba(var(--text-color-rgb), 0.1);
        }
      }
      &-40 {
        &:active {
          background-color: rgba(var(--text-color-rgb), 0.4);
        }
      }
      &-reviews-pill:active {
        background-color: var(--c-alert-positive-h);

        & svg {
          color: var(--c-alert-positive);
        }
      }
    }
  }
}

.transition {
  transition: $cta-transition;
  &--max-height {
    transition: max-height 0.3s ease-in-out;
  }
  &--opacity {
    transition: opacity $cta-transition-timing;
  }
  &--carousel {
    transition: 0.4s $carousel-transition;
  }
}
