$breakpoints: (
  screen0: 48em,
  screen1024: 64em,
  screen1152: 72em,
);

@function get-breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @return map-get($breakpoints, $breakpoint);
  }

  @warn "The key #{$breakpoint} is not in the map '$breakpoints'";
  @return null;
}

@mixin media($breakpoint, $direction: min) {
  $breakpoint-value: get-breakpoint($breakpoint);

  @if $direction == max {
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  } @else {
    @media (min-width: ($breakpoint-value)) {
      @content;
    }
  }
}
