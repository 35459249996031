.map-overlay {
  background-color: var(--c-map-background);
  opacity: 0.7;

  @include media(screen1024) {
    opacity: 0;
    visibility: visible;
    &:hover {
      opacity: 0.7;
    }
  }
}

.show-map-overlay {
  opacity: 0.7;
  visibility: visible;
}

.maps-marker-label {
  color: var(--c-map-label);
  background-color: var(--c-map-label-bg);
  border: convert-to-rem(1) solid $c-white-130;
  padding: $map-padding;
  border-radius: $map-border-radius;

  &--poi {
    color: var(--c-map-label-poi);
    background-color: var(--c-map-label-bg-poi);
  }
}
