.gallery-modal {
  position: fixed;
  top: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;

  &--active {
    top: 0;
    opacity: 1;
    visibility: visible;
  }
}
