/* || SELECT DROPDOWNS */
.select-wrapper {
  position: relative;
  max-width: convert-to-rem(200);

  &--fixed {
    width: convert-to-rem(200);
  }

  &::after {
    position: absolute;
    right: 1rem;
    top: 50%;
  }

  &--primary {
    &::after {
      content: "";
      transform: translateY(-50%);
      pointer-events: none;
      border-left: convert-to-rem(4) solid transparent;
      border-right: convert-to-rem(4) solid transparent;
      border-top: convert-to-rem(4) solid var(--text-color);
    }
  }

  &--alt {
    &::after {
      content: "";
      pointer-events: none;
      border-style: solid;
      border-width: convert-to-rem(1) convert-to-rem(1) 0 0;
      display: inline-block;
      height: convert-to-rem(7.2);
      width: convert-to-rem(7.2);
      transform: translateY(-80%) rotate(135deg);
    }
  }

  &--dark {
    &::after {
      border-top-color: var(--btn-round-primary-text-color);
      border-right-color: var(--btn-round-primary-text-color);
    }

    & .select {
      color: var(--btn-round-primary-text-color);
      background-color: var(--btn-round-primary-color);
    }
  }
}

/* Rotate chevron on custom select dropdowns when open */
.chevron-up {
  &::after {
    transform: translateY(-50%) rotate(180deg);
  }
}
.select-wrapper--alt.chevron-up {
  &::after {
    transform: translateY(-20%) rotate(315deg);
  }
}

.select {
  background-color: transparent;
  color: var(--text-color);
  width: 100%;
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  border: convert-to-rem(1) solid rgba(var(--text-color-rgb), 0.2);
  border-radius: 2em;
  appearance: none;
  outline: none;
  cursor: pointer;

  &--square {
    color: var(--text-color);
    width: 100%;
    padding: 1rem 2.5rem 1rem 1rem;
    background-color: transparent;
    border: convert-to-rem(1) solid rgba(var(--text-color-rgb), 0.2);
    border-radius: convert-to-rem(5);
    appearance: none;
    cursor: pointer;
  }

  &:focus {
    box-shadow: 0px 0px 0px convert-to-rem(4) var(--select-focus-outline);
  }

  &--nav {
    padding-top: convert-to-rem(5);
    padding-bottom: convert-to-rem(5);
    border-color: var(--text-color);
    font-size: $btn-round-font-size-mob;

    @include media(screen0) {
      padding: convert-to-rem(6) 2.5rem convert-to-rem(6) convert-to-rem(15);
      font-size: $btn-round-font-size-desk;
    }
  }

  option {
    color: $c-black;
  }
}

/* || CUSTOM DROPDOWN */
.custom-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  overflow: hidden;

  &--max {
    max-height: convert-to-rem(240);
    overflow: auto;
  }

  li {
    background-color: var(--custom-dropdown-color);
    color: var(--custom-dropdown-text-color);
    padding: 1rem 1.25rem;
    margin: 0;
    cursor: pointer;

    &:not(:last-of-type) {
      border-bottom: convert-to-rem(1) solid rgba($c-black, 0.2);
    }

    &::before {
      display: none;
    }

    &:hover,
    &:focus {
      background-color: var(--custom-dropdown-color-h);
      outline: none;
    }
  }

  &--active {
    display: block;
  }

  &--footer {
    top: unset;
    bottom: 100%;
  }
}

/* || TEXT INPUTS */
.input {
  background-color: transparent;
  border: none;
  color: var(--text-color);

  &--fade {
    background-color: #{rgba($c-white, 0.25)};
  }

  &::placeholder {
    opacity: 0.3;
    color: var(--text-color);
  }

  &:focus {
    outline: none;
  }
}

/* || FORMS */
.form-footer {
  padding: 1rem 0;
  border-bottom: convert-to-rem(1) solid rgba(var(--text-color-rgb), 0.2);
}

/*  || VALIDATION */
.invalid-field {
  &--bottom {
    border-color: rgba(var(--c-alert-negative));
    box-shadow: $invalid-box-shadow;
  }
}

.invalid-t {
  color: rgba(var(--c-alert-negative));
}

/* || UNOBTRUSIVE VALIDATION */

// hide valid fields to prevent layout interference
.field-validation-valid {
  display: none;
}

.input.border,
.input.border--bottom {
  border-color: var(--field-border);
  &.input-validation-error {
    border-color: rgba(var(--c-alert-negative));
  }
}

.valid {
  &.input.border,
  &.input.border--bottom {
    border-color: var(--field-valid-highlight);
  }
}

/* || NOTIFICAITONS */

.notification {
  &--error {
    background-color: #{$c-alert-negative};
    color: #{$c-white};
  }

  &--info {
    background-color: #{$c-primary};
    color: var(--btn-primary-text-color);
  }
}

/* || CHECKBOX */

.checkbox-field {
  position: relative;

  input[type="checkbox"] {
    opacity: 0;

    padding: 0;
    margin: 0;

    position: absolute;
  }

  label {
    display: inline-block;
    position: relative;

    padding-left: 2rem;
  }

  label::before,
  label::after {
    position: absolute;
  }

  label::before {
    content: "";
    display: inline-block;

    height: 1.5rem;
    width: 1.5rem;

    left: 0;
    top: 0;

    border: 1px solid var(--checkbox-outer-border);
    border-radius: 0.2rem;
  }

  label::after {
    display: inline-block;

    height: 0.5rem;
    width: 0.75rem;

    left: 0.35rem;
    top: 0.35rem;

    border-left-width: 2px;
    border-bottom-width: 2px;
    border-left-style: solid;
    border-bottom-style: solid;

    transform: rotate(-45deg);
  }

  input[type="checkbox"]:checked ~ label::after {
    content: "";

    border-left-color: var(--checkbox-inner-tick);
    border-bottom-color: var(--checkbox-inner-tick);
  }

  input[type="checkbox"]:checked ~ label::before {
    background-color: var(--checkbox-inner-fill);
    border-left-color: var(--checkbox-inner-tick);
    border-bottom-color: var(--checkbox-inner-tick);
  }
}
