/* || LINK CTAS */
.cta {
  color: var(--text-color);
  background: none;
  border: none;
  cursor: pointer;
  transition: $cta-transition;

  &--sm {
    text-decoration: underline;
    &:hover {
      color: var(--cta-sm-text-color-h);
    }
    &:active {
      color: var(--cta-sm-text-color-active);
    }
  }

  &--lg {
    text-decoration: none;
    &:hover {
      opacity: $cta-lg-opacity;
      color: var(--cta-lg-text-color-h);
    }
    &:active {
      color: var(--cta-lg-text-color-active);
    }
  }

  &--card {
    border: $cta-card-border;
    text-decoration: inherit;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        border-color: var(--text-color-rgb);
        opacity: $cta-card-opacity;
      }
    }
  }

  &--img-card {
    text-decoration: inherit;
    color: inherit;
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        img {
          opacity: 0.7;
        }
        .t-h4 {
          text-decoration: $cta-img-card-text-hover-decoration;
          opacity: $cta-img-card-text-hover-opacity;
        }
      }
    }
  }
}

/* || BUTTON CTAS */
.btn,
.btn-round,
.btn-anchor {
  letter-spacing: 0.06em;
  line-height: initial;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  border: convert-to-rem(1) solid;
  transition: $cta-transition;
  background-clip: padding-box;
}

/* Main rectangular buttons */
.btn {
  font-size: $btn-font-size-mob;
  border-radius: $cta-radius;
  padding: convert-to-rem(14) convert-to-rem(20);

  @include media(screen0) {
    padding: convert-to-rem(17) convert-to-rem(34);
    font-size: $btn-font-size-desk;
  }

  /* Rectanglular Primary Btn */
  &--primary {
    background-color: var(--btn-primary-color);
    border-color: var(--btn-primary-color);
    color: var(--btn-primary-text-color);

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: var(--btn-primary-color-h);
        border-color: var(--btn-primary-color-h);
      }
    }

    &-inactive {
      background-color: var(--c-alert-inactive);
      border-color: var(--c-alert-inactive);
      color: var(--c-alert-inactive-text);
      cursor: not-allowed;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: var(--c-alert-inactive);
          border-color: var(--c-alert-inactive);
        }
      }
    }
  }

  &--secondary {
    background-color: var(--btn-secondary-color);
    border-color: var(--btn-secondary-border-color);
    color: var(--btn-secondary-text-color);

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: var(--btn-secondary-color-h);
        border-color: var(--btn-secondary-color-h);
      }
    }
  }

  &--light {
    background-color: var(--btn-light-color);
    border-color: var(--btn-light-color);
    color: var(--btn-light-text-color);

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: var(--btn-light-color-h);
        border-color: var(--btn-light-color-h);
      }
    }
  }
  &--dark {
    background-color: var(--btn-dark-color);
    border-color: var(--btn-dark-color);
    color: var(--btn-dark-text-color);

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: var(--btn-dark-color-h);
        border-color: var(--btn-dark-color-h);
      }
    }
  }

  &--gallery {
    font-size: $btn-gallery-font-size-desk;
    text-transform: $btn-round-transform;
    background-color: var(--btn-gallery-color);
    border: none;
    border-radius: 2em;
    padding: convert-to-rem(5) convert-to-rem(12);
    color: var(--btn-gallery-text-color);

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: var(--btn-gallery-color-h);
      }
    }
  }
}

/* || ROUNDED, PILL SHAPED BUTTONS */
.btn-round {
  font-size: $btn-round-font-size-mob;
  padding: convert-to-rem(5) convert-to-rem(12);
  border-radius: 1em;
  text-transform: $btn-round-transform;

  @include media(screen0) {
    padding: convert-to-rem(6) $btn-round-padding-desk;
    font-size: $btn-round-font-size-desk;
  }

  &--primary {
    background-color: var(--btn-round-primary-color);
    border-color: var(--btn-round-primary-color);
    color: var(--btn-round-primary-text-color);

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: var(--btn-round-primary-color-h);
        border-color: var(--btn-round-primary-color-h);
      }
    }
  }

  &--secondary {
    background-color: transparent;
    border-color: var(--text-color);
    color: var(--text-color);

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: rgba(var(--text-color-rgb), 0.05);
      }
    }
  }
}

/* || ANCHOR BUTTONS */
/* similar to ROUNDED, with slight size differences */
.btn-anchor {
  font-size: $btn-round-font-size-mob;
  padding: convert-to-rem(10) convert-to-rem(20) convert-to-rem(10)
    convert-to-rem(18);
  border-radius: 2em;
  background-color: transparent;
  border-color: var(--btn-anchor-border-color);
  color: var(--btn-anchor-text-color);
  text-transform: $btn-round-transform;

  @include media(screen0) {
    padding: convert-to-rem(15) convert-to-rem(20);
    font-size: $btn-round-font-size-desk;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover,
    &:active {
      background-color: var(--btn-anchor-color-h);
      border-color: var(--btn-anchor-border-color-h);
      color: var(--btn-anchor-text-color);
    }

    &:active {
      outline: $btn-anchor-active-outline;
    }
  }

  &--light {
    border-color: var(--btn-anchor-light-border-color);
  }
}
