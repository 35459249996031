/* || BRAND SPECIFIC */
$display-only-wilde: none !default;

/* TYPOGRAPHY */
$font-family: "NNNouvelleGroteskSTD-Normal" sans-serif !default;
$font-family-tags: "NNNouvelleGroteskSTD-Normal" sans-serif !default;
$font-variant-numeric: normal !default;
$icon-info-align: left !default;

/* || LISTS */
$ul-border-radius: 0 !default;
$blog-ul-mask: url("../assets/icons/asterisk2.svg") !default;

/* || BOX MODEL */
$margin-reviews-overlay-rating-b-mob: convert-to-rem(19) !default;
$margin-reviews-overlay-rating-b-desk: convert-to-rem(14) !default;
$border-radius: 1em !default;
$border-radius-xs: convert-to-rem(5) !default;
$border-radius-sm: convert-to-rem(10) !default;
$border-radius-lg: convert-to-rem(35) !default;
$border-radius-bottom: 1.5em !default;
$border-radius-top: 1.5em !default;
$border-radius-left: 1.5em !default;
$border-radius-right: 1.5em !default;
$padding-city-header-mob: convert-to-rem(98) !default;
$padding-city-header-desk: convert-to-rem(105) !default;
$padding-city-properties: convert-to-rem(98) !default;
$padding-guest-services-header-mob: convert-to-rem(84) !default;
$padding-guest-services-header-desk: convert-to-rem(118) !default;
$padding-guest-services-cta-mob: convert-to-rem(15) !default;
$padding-guest-services-cta-desk: convert-to-rem(25) !default;
$padding-anchor-link-mob: 0 !default;
$padding-anchor-link-desk: 0 !default;
$block-spacing-y-mob: convert-to-rem(46) !default;

/* || CTAs */
$cta-transition-timing: 0.1s cubic-bezier(0, 0, 0.58, 1) !default;
$cta-transition: all $cta-transition-timing;
$cta-lg-opacity: 0.5 !default;
$cta-radius: 0 !default;
$cta-img-card-text-hover-opacity: 1 !default;
$cta-img-card-text-hover-decoration: underline !default;
$cta-card-border: convert-to-rem(1) solid rgba(var(--text-color-rgb), 0.3) !default;
$cta-card-opacity: 1 !default;

$btn-font-size-mob: 1rem !default;
$btn-font-size-desk: 1.125rem !default;
$btn-anchor-active-outline: 0.3125em solid $c-alert-positive-h !default;
$btn-round-transform: uppercase !default;
$btn-round-font-size-mob: convert-to-rem(14) !default;
$btn-round-font-size-desk: convert-to-rem(15) !default;
$btn-round-padding-desk: convert-to-rem(15) !default;
$btn-gallery-font-size-desk: convert-to-rem(14) !default;

/* || SIZEs */
$min-h-link-grid-mob: convert-to-rem(150) !default;
$min-h-link-grid-desk: convert-to-rem(155) !default;
$max-h-img-promo: convert-to-rem(436) !default;
$h-icon: 1.5rem !default;

/* || DISPLAYS */
$cityHotelsGap: convert-to-rem(12) !default;
$guest-services-footer-gap: convert-to-rem(40) !default;
$grid-featured-hotels-row-mob: convert-to-rem(154) !default;
$grid-featured-hotels-row-desk: convert-to-rem(262) !default;
$gap-nav-links-mob: convert-to-rem(20) !default;
$gap-nav-links-desk: convert-to-rem(20) !default;

/* || CAROUSELS */
$carousel-transition: cubic-bezier(0.19, 0.69, 0.41, 1);
$swiper-nav-hover-scale: scale(1.15) !default;

/* || HEADER */
$logo-w-mob: 113 !default;
$logo-w-desk: 135 !default;
$logo-h-mob: 31 !default;
$logo-h-desk: 33 !default;

/* || FORMS */
$invalid-box-shadow: 0px convert-to-rem(3) 0px 0px
  rgba(var(--c-alert-negative), 0.2) !default;

/* || MAPS */
$map-border-radius: convert-to-rem(4) !default;
$map-padding: convert-to-rem(10) convert-to-rem(14) !default;
$map-label-margin: -5rem !default;

/* || ICONS */
$icon-acordion-size-mob: convert-to-rem(12) !default;
$icon-acordion-size-desk: convert-to-rem(12) !default;
$icon-guest-services-icon-size-mob: convert-to-rem(18) !default;
$icon-guest-services-icon-size-desk: convert-to-rem(30) !default;

/* || BLOG */
$blogHeading: headingThree !default;
$blogSubHeading: copyBig !default;
$blog-italic: copySmall !default;
$ul-icon-width: convert-to-rem(10) !default;
$ul-icon-height: convert-to-rem(10) !default;
$blog-figcaption-opacity: 0.7 !default;

/* || MY ACOOUNT */
$myAccountHeaderMobileFontSize: convert-to-rem(38) !default;
$myAccountHeaderMobileLineHeight: convert-to-rem(42) !default;

//image-with-large-links-split-component
$imageWithLargeLinksSplitComponentDesktopLeftGutter: 8vw !default;
