.navigation {
  position: fixed;
  top: 0;
  right: -100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
}

.navigation-active {
  right: 0;
  opacity: 1;
  visibility: visible;
}
