.reviews-modal {
  position: fixed;
  top: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  left: 50%;
  transform: translateX(-50%);

  &--active {
    top: 0;
    opacity: 1;
    visibility: visible;
    @include media(screen1024) {
      & > div {
        margin-top: convert-to-rem(84);
        margin-bottom: convert-to-rem(84);
      }
    }
  }
}
