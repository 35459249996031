@import "~swiper/scss";
@import "~swiper/scss/pagination";
@import "~swiper/scss/navigation";
@import "~swiper/css/grid";
@import "~swiper/css/free-mode";
@import "~swiper/css/keyboard";

.swiper {
  width: 100%;
  height: 100%;
  overflow: visible;
}

.swiper-slide {
  height: auto;
}

[js-swiper-type="guestServicesExtraBtns"] {
  .swiper-slide {
    width: max-content;
  }
}

/* Featured hotels only. No page bleed, and is a grid carousel */
[js-swiper-type="featuredHotels"] {
  overflow: hidden;

  & .swiper-wrapper {
    row-gap: convert-to-rem(30);
  }
}

[js-swiper-type="featuredHotelsMyAccount"] {
  @include media(screen1024) {
    overflow: hidden;
  }
}

/* || EASING */
.swiper-wrapper {
  transition-timing-function: $carousel-transition;
  box-sizing: border-box;
}

/* || PAGINATION OVERWRITES */

.swiper-pagination[class*="swiper-pagination-"] {
  position: relative;
  z-index: 5;
  margin-top: convert-to-rem(40);
  @include media(screen1024) {
    margin-top: convert-to-rem(74);
  }

  .swiper-pagination-bullet,
  .swiper-pagination-bullet-active {
    --swiper-pagination-bullet-size: 7px;
    background-color: var(--text-color);

    &:focus-visible {
      outline: convert-to-rem(1) solid var(--text-color);
      outline-offset: convert-to-rem(2);
    }
  }

  [js-swiper-type="galleryMob"] .swiper-pagination-bullet,
  [js-swiper-type="galleryMob"] .swiper-pagination-bullet-active {
    --swiper-pagination-bullet-size: 5px;
  }
}

[js-swiper-type="galleryModal"] + .swiper-pagination {
  position: absolute;
  bottom: 2rem;
  @include media(screen1024) {
    position: relative;
    margin-top: convert-to-rem(60);
  }
}

.swiper-gallery-wrapper {
  height: convert-to-rem(325);
}

.pagination-gallery {
  --swiper-pagination-bullet-inactive-opacity: 0.4;

  margin: 0 auto;
  background-color: #{rgba($c-black, 0.5)};
  padding: convert-to-rem(11) convert-to-rem(12);
  border-radius: 2rem;
  position: absolute;
  bottom: 1rem !important;

  .swiper-pagination-bullet,
  .swiper-pagination-bullet-active {
    background-color: $c-white;
    left: 0 !important;

    &:focus {
      outline: convert-to-rem(2) solid $c-white;
      outline-offset: convert-to-rem(2);
    }
  }
}

/* || NAVIGATION OVERWRITES */

.swiper-button-next,
.swiper-button-prev {
  z-index: 5;
  @include media(screen0) {
    position: relative;
    color: var(--text-color);
    width: convert-to-rem(52);
    height: convert-to-rem(52);
    border: convert-to-rem(1) solid var(--text-color);
    border-radius: 50%;
    &::after {
      font-size: 1rem;
      position: absolute;
      top: 50%;
    }

    &:hover {
      transform: $swiper-nav-hover-scale;
      background-color: var(--swiper-nav-color-h);
      border: none;
    }
    &:active {
      transform: $swiper-nav-hover-scale;
      background-color: var(--swiper-nav-color-active);
      border-color: var(--swiper-nav-color-active);
    }
  }
}

.swiper-button-prev {
  margin-right: convert-to-rem(20);
  &::after {
    transform: translate(convert-to-rem(-2), -50%);
  }
}

.swiper-button-next {
  &::after {
    transform: translate(convert-to-rem(2), -50%);
  }
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0.1;
}
