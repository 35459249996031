@include media(screen1024) {
  [js-animate="fade-in"] {
    opacity: 0;
    transition: opacity 0.5s;
  }
  [js-animate="fade-in-stagger"] {
    & .animate--stagger {
      opacity: 0;
      transition: opacity 0.5s;
      transition-delay: calc(100ms * var(--delay));
    }
  }
  [js-animate="up"] {
    overflow: hidden;
    & > * {
      transition: transform 1s;
      transform: translateY(100%);
    }
  }
  [js-animate="fade-up"] {
    opacity: 0;
    transition: transform 0.5s, opacity 0.5s;
    transform: translateY(convert-to-rem(30));
  }

  .animate {
    /* Fade In */
    &--fade-in {
      opacity: 1;
    }

    /* Fade in staggered */
    &--fade-in-stagger {
      & .animate--stagger {
        opacity: 1;
      }
    }

    /* Slide up (no fade) */
    &--up {
      & > * {
        transform: translateY(0);
      }
    }

    /* Slide up (with fade) */
    &--fade-up {
      opacity: 1;
      transform: translateY(0);
    }

    /* --delay var is set inline on the razor template, will be the index of the looped item */
    &--stagger {
      transition-delay: calc(100ms * var(--delay));
    }
  }
}

/* Remove animations for users who prefer not to see them */
@media (prefers-reduced-motion) {
  [js-animate] {
    transition: none;
  }
}
