div[data-content-element-type-alias="savedSearches"] {
  display: none;

  .saved-searches-carousel-wrapper {
    margin-left: 0;
    margin-right: -5vw;

    @include media(screen0) {
      margin-left: 8vw;
      margin-right: -4vw;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: convert-to-rem(42);
    height: convert-to-rem(42);
  }
}
