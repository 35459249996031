.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.absolute-relative {
  position: absolute;
  @include media(screen1024) {
    position: relative;
  }
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
  &--desk-only {
    @include media(screen1024) {
      position: sticky;
    }
  }
}

.top {
  &--0 {
    top: 0;
  }
  &--2 {
    top: 2rem;
  }
  &--5 {
    top: 5rem;
  }
  &--2px {
    top: 2px;
  }
  &--50 {
    top: 50%;
    transform: translateY(-50%);
  }
  &--neg-3 {
    top: -3px;
  }
  &--neg-30--desk {
    @include media(screen1024) {
      top: -30px;
    }
  }
  &--neg-1 {
    top: -1rem;
  }
  &--neg-1_5 {
    top: -1.5rem;
  }
}

.bottom {
  &--0 {
    bottom: 0;
  }
  &--1_5 {
    bottom: 1.5rem;
  }
  &--2 {
    bottom: 2rem;
  }
  &--3 {
    bottom: 3rem;
  }
  &--desk-auto {
    @include media(screen1024) {
      bottom: auto;
    }
  }
}

.right {
  &--0 {
    right: 0;
  }
  &--1_5 {
    right: 1.5rem;
  }
  &--neg-1 {
    right: -1rem;
  }
  &--neg-1_5 {
    right: -1.5rem;
  }
}

.left {
  &--0 {
    left: 0;
  }
  &--neg-1 {
    left: -1rem;
  }
}
