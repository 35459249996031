.fill {
  &--primary {
    color: var(--btn-primary-color);
  }
  &--primary-dark {
    color: $c-primary-dark;
  }
  &--black {
    color: $c-black;
  }
  &--grey {
    color: $c-grey;
  }
  &--review-unfilled {
    color: var(--c-review-unfilled);
  }
}

.icon {
  &--xxs {
    max-width: 0.5em;
    max-height: 0.5em;
  }
  &--xs {
    max-width: 0.75rem;
    max-height: 0.75rem;
  }
  &--sm {
    max-width: 1rem;
    max-height: 1rem;
  }
  &--md {
    max-width: 1.25rem;
    max-height: 1.25rem;

    &-lg {
      max-width: 1.25rem;
      max-height: 1.25rem;
      @include media(screen1024) {
        max-width: 1.5rem;
        max-height: 1.5rem;
      }
    }
  }
  &--lg {
    max-width: 1.5rem;
    max-height: 1.5rem;
  }
  &--xl {
    max-width: 2rem;
    max-height: 2rem;
  }
  &--xs-sm {
    max-width: 0.75rem;
    max-height: 0.75rem;
    @include media(screen1024) {
      max-width: 1rem;
      max-height: 1rem;
    }
  }
  &--wilde-circle {
    max-width: 5.5rem;
    max-height: 3rem;
  }
  &--wilde-logo {
    max-width: convert-to-rem(170);
    max-height: convert-to-rem(50);
  }
  &--header-asterisk {
    max-width: convert-to-rem(25);
    max-height: convert-to-rem(25);
    @include media(screen1024) {
      max-width: convert-to-rem(35);
      max-height: convert-to-rem(35);
    }
  }
  &--mini-asterisk {
    max-width: convert-to-rem(16);
    max-height: convert-to-rem(16);
    @include media(screen1024) {
      max-width: convert-to-rem(24);
      max-height: convert-to-rem(24);
    }
  }
  &--image-promo {
    max-width: convert-to-rem(37);
    max-height: convert-to-rem(12);
    @include media(screen1024) {
      max-width: convert-to-rem(52);
      max-height: convert-to-rem(17);
    }
  }
  &--feature-grid-underline {
    max-height: convert-to-rem(7);
  }
  &--guest-services-cta {
    svg {
      max-height: $icon-guest-services-icon-size-mob;
      @include media(screen1024) {
        max-height: $icon-guest-services-icon-size-desk;
      }
    }
  }
  &--guest-services-extra-cta {
    svg {
      max-height: convert-to-rem(12.5);
      @include media(screen1024) {
        max-height: convert-to-rem(16.5);
      }
    }
  }
  &--promo-card-asterisk {
    ::after {
      content: "";
      mask-image: var(--c-promo-card-asterisk-icon);
      mask-repeat: no-repeat;
      mask-position: left;
      mask-size: cover;
      position: absolute;
      top: 50%;
      right: -10%;
      transform: translateY(-50%);
      background-color: var(--c-promo-card-asterisk);
      width: 70%;
      height: 110%;
      z-index: -1;
    }
  }
  &--post-asterisk {
    &::after {
      content: "";
      width: convert-to-rem(12);
      height: convert-to-rem(12);
      margin-bottom: convert-to-rem(6);
      margin-left: convert-to-rem(6);
      display: inline-block;
      background-color: #{$c-black};
      mask-image: var(--c-promo-card-asterisk-icon);
      mask-repeat: no-repeat;
      mask-position: left;
      mask-size: cover;
    }
  }
  &--account-sub-nav {
    width: convert-to-rem(25);
    height: convert-to-rem(25);

    @include media(screen1024) {
      width: convert-to-rem(30);
      height: convert-to-rem(30);
    }
  }
  &--account-nav {
    width: convert-to-rem(44);
    height: convert-to-rem(36);
  }
}

.icon-accordion {
  max-width: $icon-acordion-size-mob;
  max-height: $icon-acordion-size-mob;
  transition: $cta-transition;

  @include media(screen1024) {
    max-width: $icon-acordion-size-desk;
    max-height: $icon-acordion-size-desk;
  }
}

/* Use rotate() to turn plus into a minus */
.accordion-active .icon-accordion {
  transform: rotate(45deg);
}
.accordion-active .icon-accordion-chevron {
  transform: rotate(180deg);
}
