/* Functions */
@function rem-calc($values, $base-value: $rem-base) {
  $max: length($values);

  @if $max == 1 {
    @return convert-to-rem(nth($values, 1), $base-value);
  }

  $remValues: ();

  @for $i from 1 through $max {
    $remValues: append(
      $remValues,
      convert-to-rem(nth($values, $i), $base-value)
    );
  }

  @return $remValues;
}

@mixin typography($fs-type: headingOne) {
  @each $s-breakpoint, $breakpoint-map in map-get($font-size-groups, $fs-type) {
    @if $s-breakpoint == null {
      font-size: rem-calc(map-get($breakpoint-map, fontSize));
      line-height: rem-calc(map-get($breakpoint-map, lineHeight));
      letter-spacing: #{map-get($breakpoint-map, letterSpacing)};
      text-transform: #{map-get($breakpoint-map, textTransform)};
      font-weight: #{map-get($breakpoint-map, fontWeight)};
    } @else {
      @include media($s-breakpoint) {
        font-size: rem-calc(map-get($breakpoint-map, fontSize));
        line-height: rem-calc(map-get($breakpoint-map, lineHeight));
        letter-spacing: #{map-get($breakpoint-map, letterSpacing)};
      }
    }
  }
}

@function get-font-size($font-size) {
  @if map-has-key($font-sizes, $font-size) {
    @return map-get($font-sizes, $font-size);
  }

  @warn "The key #{$font-size} is not in the map '$font-sizes'";
  @return null;
}

@function get-line-height($line-height) {
  @if map-has-key($line-heights, $line-height) {
    @return map-get($line-heights, $line-height);
  }

  @warn "The key #{$line-height} is not in the map '$line-heights'";
  @return null;
}

@function get-letter-spacing($letter-spacing) {
  @if map-has-key($letter-spacings, $letter-spacing) {
    @return map-get($letter-spacings, $letter-spacing);
  }

  @warn "The key #{$letter-spacing} is not in the map '$letter-spacings'";
  @return null;
}

@function get-text-transform($text-transform) {
  @if map-has-key($text-transforms, $text-transform) {
    @return map-get($text-transforms, $text-transform);
  }

  @warn "The key #{$text-transform} is not in the map '$text-transforms'";
  @return null;
}

@function get-font-weight($font-weight) {
  @if map-has-key($font-weights, $font-weight) {
    @return map-get($font-weights, $font-weight);
  }

  @warn "The key #{$font-weight} is not in the map '$font-weights'";
  @return null;
}

$font-sizes: (
  headingZero-0: 60,
  headingZero-1024: 102,
  headingOne-0: 48,
  headingOne-1024: 84,
  headingTwo-0: 48,
  headingTwo-1024: 58,
  headingThree-0: 36,
  headingThree-1024: 38,
  headingFour-0: 26,
  headingFour-1024: 28,
  headingFive-0: 20,
  headingFive-1024: 22,
  copyHuge-0: 20,
  copyHuge-1024: 22,
  copyBig-0: 20,
  copyBig-1024: 22,
  copySmall-0: 16,
  copySmall-1024: 18,
  copyTiny-0: 14,
  copyTiny-1024: 16,
  notes-0: 14,
  notes-1024: 16,
  lists-0: 14,
  lists-1024: 16,
  tagsBig-0: 14,
  tagsBig-1024: 16,
  tagsSmall-0: 14,
  tagsSmall-1024: 16,
  tagsCaps-0: 14,
  tagsCaps-1024: 16,
  pill-0: 12,
  pill-1024: 14,
) !default;

$line-heights: (
  headingZero-0: 60,
  headingZero-1024: 102,
  headingOne-0: 48,
  headingOne-1024: 84,
  headingTwo-0: 48,
  headingTwo-1024: 64,
  headingThree-0: 42,
  headingThree-1024: 41,
  headingFour-0: 30,
  headingFour-1024: 34,
  headingFive-0: 22,
  headingFive-1024: 26,
  copyHuge-0: 28,
  copyHuge-1024: 34,
  copyBig-0: 28,
  copyBig-1024: 34,
  copySmall-0: 24,
  copySmall-1024: 30,
  copyTiny-0: 21,
  copyTiny-1024: 26,
  notes-0: 18,
  notes-1024: 22,
  lists-0: 23,
  lists-1024: 29,
  tagsBig-0: 17,
  tagsBig-1024: 19,
  tagsSmall-0: 17,
  tagsSmall-1024: 19,
  tagsCaps-0: 17,
  tagsCaps-1024: 19,
  pill-0: 18,
  pill-1024: 14,
) !default;

$letter-spacings: (
  headingZero-0: 0,
  headingZero-1024: 0,
  headingOne-0: 0,
  headingOne-1024: 0,
  headingTwo-0: 0,
  headingTwo-1024: 0,
  headingThree-0: 0,
  headingThree-1024: 0,
  headingFour-0: 0,
  headingFour-1024: 0,
  headingFive-0: 0,
  headingFive-1024: "0.01em",
  copyHuge-0: "0.01em",
  copyHuge-1024: "0.01em",
  copyBig-0: "0.01em",
  copyBig-1024: "0.01em",
  copySmall-0: "0.015em",
  copySmall-1024: "0.015em",
  copyTiny-0: "0.02em",
  copyTiny-1024: "0.02em",
  notes-0: "0.02em",
  notes-1024: "0.02em",
  lists-0: "0.0175em",
  lists-1024: "0.02em",
  tagsBig-0: "0.1em",
  tagsBig-1024: "0.12em",
  tagsSmall-0: "0.1em",
  tagsSmall-1024: "0.12em",
  tagsCaps-0: "0.1em",
  tagsCaps-1024: "0.12em",
  pill-0: "0.02em",
  pill-1024: "0.02em",
) !default;

$text-transforms: (
  headingZero-0: "none",
  headingZero-1024: "none",
  headingOne-0: "none",
  headingOne-1024: "none",
  headingTwo-0: "none",
  headingTwo-1024: "none",
  headingThree-0: "none",
  headingThree-1024: "none",
  headingFour-0: "none",
  headingFour-1024: "none",
  headingFive-0: "none",
  headingFive-1024: "none",
  copyHuge-0: "none",
  copyHuge-1024: "none",
  copyBig-0: "none",
  copyBig-1024: "none",
  copySmall-0: "none",
  copySmall-1024: "none",
  copyTiny-0: "none",
  copyTiny-1024: "none",
  notes-0: "none",
  notes-1024: "none",
  lists-0: "none",
  lists-1024: "none",
  tagsBig-0: "uppercase",
  tagsBig-1024: "uppercase",
  tagsSmall-0: "uppercase",
  tagsSmall-1024: "uppercase",
  tagsCaps-0: "uppercase",
  tagsCaps-1024: "uppercase",
  pill-0: "uppercase",
  pill-1024: "uppercase",
) !default;

$font-weights: (
  normal: 400,
  header: 400,
) !default;

$font-size-groups: (
  "headingZero": (
    null: (
      fontSize: get-font-size(headingZero-0),
      lineHeight: get-line-height(headingZero-0),
      letterSpacing: get-letter-spacing(headingZero-0),
      textTransform: get-text-transform(headingZero-0),
      fontWeight: get-font-weight(header),
    ),
    screen1024: (
      fontSize: get-font-size(headingZero-1024),
      lineHeight: get-line-height(headingZero-1024),
      letterSpacing: get-letter-spacing(headingZero-1024),
      textTransform: get-text-transform(headingZero-1024),
      fontWeight: get-font-weight(header),
    ),
  ),
  "headingOne": (
    null: (
      fontSize: get-font-size(headingOne-0),
      lineHeight: get-line-height(headingOne-0),
      letterSpacing: get-letter-spacing(headingOne-0),
      textTransform: get-text-transform(headingOne-0),
      fontWeight: get-font-weight(header),
    ),
    screen1024: (
      fontSize: get-font-size(headingOne-1024),
      lineHeight: get-line-height(headingOne-1024),
      letterSpacing: get-letter-spacing(headingOne-1024),
      textTransform: get-text-transform(headingOne-1024),
      fontWeight: get-font-weight(header),
    ),
  ),
  "headingTwo": (
    null: (
      fontSize: get-font-size(headingTwo-0),
      lineHeight: get-line-height(headingTwo-0),
      letterSpacing: get-letter-spacing(headingTwo-0),
      textTransform: get-text-transform(headingOne-0),
      fontWeight: get-font-weight(header),
    ),
    screen1024: (
      fontSize: get-font-size(headingTwo-1024),
      lineHeight: get-line-height(headingTwo-1024),
      letterSpacing: get-letter-spacing(headingTwo-1024),
      textTransform: get-text-transform(headingOne-1024),
      fontWeight: get-font-weight(header),
    ),
  ),
  "headingThree": (
    null: (
      fontSize: get-font-size(headingThree-0),
      lineHeight: get-line-height(headingThree-0),
      letterSpacing: get-letter-spacing(headingThree-0),
      textTransform: get-text-transform(headingOne-0),
      fontWeight: get-font-weight(header),
    ),
    screen1024: (
      fontSize: get-font-size(headingThree-1024),
      lineHeight: get-line-height(headingThree-1024),
      letterSpacing: get-letter-spacing(headingThree-1024),
      textTransform: get-text-transform(headingThree-1024),
      fontWeight: get-font-weight(header),
    ),
  ),
  "headingFour": (
    null: (
      fontSize: get-font-size(headingFour-0),
      lineHeight: get-line-height(headingFour-0),
      letterSpacing: get-letter-spacing(headingFour-0),
      textTransform: get-text-transform(headingFour-0),
      fontWeight: get-font-weight(header),
    ),
    screen1024: (
      fontSize: get-font-size(headingFour-1024),
      lineHeight: get-line-height(headingFour-1024),
      letterSpacing: get-letter-spacing(headingFour-1024),
      textTransform: get-text-transform(headingFour-1024),
      fontWeight: get-font-weight(header),
    ),
  ),
  "headingFive": (
    null: (
      fontSize: get-font-size(headingFive-0),
      lineHeight: get-line-height(headingFive-0),
      letterSpacing: get-letter-spacing(headingFive-0),
      textTransform: get-text-transform(headingFive-0),
      fontWeight: get-font-weight(header),
    ),
    screen1024: (
      fontSize: get-font-size(headingFive-1024),
      lineHeight: get-line-height(headingFive-1024),
      letterSpacing: get-letter-spacing(headingFive-1024),
      textTransform: get-text-transform(headingFive-1024),
      fontWeight: get-font-weight(header),
    ),
  ),
  "copyHuge": (
    null: (
      fontSize: get-font-size(copyHuge-0),
      lineHeight: get-line-height(copyHuge-0),
      letterSpacing: get-letter-spacing(copyHuge-0),
      textTransform: get-text-transform(copyHuge-0),
      fontWeight: get-font-weight(normal),
    ),
    screen1024: (
      fontSize: get-font-size(copyHuge-1024),
      lineHeight: get-line-height(copyHuge-1024),
      letterSpacing: get-letter-spacing(copyHuge-1024),
      textTransform: get-text-transform(copyHuge-1024),
      fontWeight: get-font-weight(normal),
    ),
  ),
  "copyBig": (
    null: (
      fontSize: get-font-size(copyBig-0),
      lineHeight: get-line-height(copyBig-0),
      letterSpacing: get-letter-spacing(copyBig-0),
      textTransform: get-text-transform(copyBig-0),
      fontWeight: get-font-weight(normal),
    ),
    screen1024: (
      fontSize: get-font-size(copyBig-1024),
      lineHeight: get-line-height(copyBig-1024),
      letterSpacing: get-letter-spacing(copyBig-1024),
      textTransform: get-text-transform(copyBig-1024),
      fontWeight: get-font-weight(normal),
    ),
  ),
  "copySmall": (
    null: (
      fontSize: get-font-size(copySmall-0),
      lineHeight: get-line-height(copySmall-0),
      letterSpacing: get-letter-spacing(copySmall-0),
      textTransform: get-text-transform(copySmall-0),
      fontWeight: get-font-weight(normal),
    ),
    screen1024: (
      fontSize: get-font-size(copySmall-1024),
      lineHeight: get-line-height(copySmall-1024),
      letterSpacing: get-letter-spacing(copySmall-1024),
      textTransform: get-text-transform(copySmall-1024),
      fontWeight: get-font-weight(normal),
    ),
  ),
  "copyTiny": (
    null: (
      fontSize: get-font-size(copyTiny-0),
      lineHeight: get-line-height(copyTiny-0),
      letterSpacing: get-letter-spacing(copyTiny-0),
      textTransform: get-text-transform(copyTiny-0),
      fontWeight: get-font-weight(normal),
    ),
    screen1024: (
      fontSize: get-font-size(copyTiny-1024),
      lineHeight: get-line-height(copyTiny-1024),
      letterSpacing: get-letter-spacing(copyTiny-1024),
      textTransform: get-text-transform(copyTiny-1024),
      fontWeight: get-font-weight(normal),
    ),
  ),
  "notes": (
    null: (
      fontSize: get-font-size(notes-0),
      lineHeight: get-line-height(notes-0),
      letterSpacing: get-letter-spacing(notes-0),
      textTransform: get-text-transform(notes-0),
      fontWeight: get-font-weight(normal),
    ),
    screen1024: (
      fontSize: get-font-size(notes-1024),
      lineHeight: get-line-height(notes-1024),
      letterSpacing: get-letter-spacing(notes-1024),
      textTransform: get-text-transform(notes-1024),
      fontWeight: get-font-weight(normal),
    ),
  ),
  "lists": (
    null: (
      fontSize: get-font-size(lists-0),
      lineHeight: get-line-height(lists-0),
      letterSpacing: get-letter-spacing(lists-0),
      textTransform: get-text-transform(lists-0),
      fontWeight: get-font-weight(normal),
    ),
    screen1024: (
      fontSize: get-font-size(lists-1024),
      lineHeight: get-line-height(lists-1024),
      letterSpacing: get-letter-spacing(lists-1024),
      fontWeight: get-font-weight(normal),
    ),
  ),
  "tagsBig": (
    null: (
      fontSize: get-font-size(tagsBig-0),
      lineHeight: get-line-height(tagsBig-0),
      letterSpacing: get-letter-spacing(tagsBig-0),
      textTransform: get-text-transform(tagsBig-0),
      fontWeight: get-font-weight(header),
    ),
    screen1024: (
      fontSize: get-font-size(tagsBig-1024),
      lineHeight: get-line-height(tagsBig-1024),
      letterSpacing: get-letter-spacing(tagsBig-1024),
      textTransform: get-text-transform(tagsBig-1024),
      fontWeight: get-font-weight(header),
    ),
  ),
  "tagsSmall": (
    null: (
      fontSize: get-font-size(tagsSmall-0),
      lineHeight: get-line-height(tagsSmall-0),
      letterSpacing: get-letter-spacing(tagsSmall-0),
      textTransform: get-text-transform(tagsSmall-0),
      fontWeight: get-font-weight(header),
    ),
    screen1024: (
      fontSize: get-font-size(tagsSmall-1024),
      lineHeight: get-line-height(tagsSmall-1024),
      letterSpacing: get-letter-spacing(tagsSmall-1024),
      textTransform: get-text-transform(tagsSmall-1024),
      fontWeight: get-font-weight(header),
    ),
  ),
  "tagsCaps": (
    null: (
      fontSize: get-font-size(tagsCaps-0),
      lineHeight: get-line-height(tagsCaps-0),
      letterSpacing: get-letter-spacing(tagsCaps-0),
      textTransform: get-text-transform(tagsCaps-0),
      fontWeight: get-font-weight(header),
    ),
    screen1024: (
      fontSize: get-font-size(tagsCaps-1024),
      lineHeight: get-line-height(tagsCaps-1024),
      letterSpacing: get-letter-spacing(tagsCaps-1024),
      textTransform: get-text-transform(tagsCaps-1024),
      fontWeight: get-font-weight(header),
    ),
  ),
  "pill": (
    null: (
      fontSize: get-font-size(pill-0),
      lineHeight: get-line-height(pill-0),
      letterSpacing: get-letter-spacing(pill-0),
      textTransform: get-text-transform(pill-0),
      fontWeight: get-font-weight(header),
    ),
    screen1024: (
      fontSize: get-font-size(pill-1024),
      lineHeight: get-line-height(pill-1024),
      letterSpacing: get-letter-spacing(pill-1024),
      textTransform: get-text-transform(pill-1024),
      fontWeight: get-font-weight(header),
    ),
  ),
) !default;
