html {
  box-sizing: border-box;
  font-size: 100%;
  font-variant-numeric: $font-variant-numeric;
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  font-family: $font-family;
  text-underline-offset: 0.25rem;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  margin: 0;
  padding: 0;
}

iframe {
  max-width: 100%;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;

  li {
    /* These styles cannot be done with utility classes as lists will almost always come from cms rich text fields */
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-left: 0.25rem;

    &::before {
      content: "";
      background: var(--text-color);
      width: 0.25em;
      height: 0.25em;
      -webkit-transform: none;
      transform: none;
      border-radius: $ul-border-radius;
    }
  }
}

ol {
  & li {
    display: flex;
    align-items: baseline;
    gap: 1rem;
    margin-left: 0.25rem;
  }
}

dl,
dd,
dt {
  margin: 0;
  padding: 0;
}

button[tabindex="0"] {
  cursor: pointer;
}

button {
  padding: 0;
  background: none;
  border: none;
  font-variant-numeric: $font-variant-numeric;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: normal;
  margin: 0;
}

figure {
  margin: 0;
}
