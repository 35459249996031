@charset 'UTF-8';

/* Font Faces */
@font-face {
  font-family: "Mediaan-Regular";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/mediaan/Mediaan-Regular.woff2") format("woff2"),
    url("../assets/fonts/mediaan/Mediaan-Regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Mediaan-Italic";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/mediaan/Mediaan-Italic.woff2") format("woff2"),
    url("../assets/fonts/mediaan/Mediaan-Italic.woff") format("woff");
  font-display: swap;
}

@import "01-functions";
@import "02-wilde-settings";
@import "style";
