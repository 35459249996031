/* || BORDERS */
@mixin border($border: border) {
  #{$border}: convert-to-rem(1) solid rgba(var(--text-color-rgb), 0.15);
}

@mixin feature-grid-border($border: border) {
  #{$border}: convert-to-rem(1) solid rgba(var(--text-color-rgb), 0.3);
}

@mixin feature-grid-border-top {
  &::before {
    content: "";
    width: 80%;
    height: convert-to-rem(1);
    background-color: rgba(var(--text-color-rgb), 0.3);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

@mixin feature-grid-border-bottom {
  &::after {
    content: "";
    width: 80%;
    height: convert-to-rem(1);
    background-color: rgba(var(--text-color-rgb), 0.3);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
