/* || BRAND SPECIFIC */
$display-only-wilde: block;

/* || TYPOGRAPHY */
$font-family: "Mediaan-Regular" serif;
$font-family-tags: "Mediaan-Italic" serif;
$font-variant-numeric: oldstyle-nums;
$icon-info-align: center;

/* || LISTS */
$ul-border-radius: 50%;
$blog-ul-mask: url("../assets/icons/wilde-tick.svg");

/* || BOX MODEL */
$margin-reviews-overlay-rating-b-mob: convert-to-rem(5);
$margin-reviews-overlay-rating-b-desk: convert-to-rem(5);
$border-radius: convert-to-rem(5);
$border-radius-lg: convert-to-rem(5);
$border-radius-bottom: 0.5em;
$border-radius-top: 0.5em;
$border-radius-left: 0.5em;
$border-radius-right: 0.5em;
$padding-city-header-mob: 0;
$padding-city-header-desk: 0;
$padding-guest-services-header-mob: convert-to-rem(91);
$padding-guest-services-header-desk: convert-to-rem(145);
$padding-city-properties: convert-to-rem(134);
$padding-guest-services-cta-mob: convert-to-rem(15) 0;
$padding-guest-services-cta-desk: convert-to-rem(20);
$padding-anchor-link-mob: convert-to-rem(10);
$padding-anchor-link-desk: convert-to-rem(12);
$block-spacing-y-mob: convert-to-rem(90);

/* || CTAs */
$cta-lg-opacity: 1;
$cta-radius: 0.25rem;
$cta-img-card-text-hover-opacity: 0.7;
$cta-img-card-text-hover-decoration: none;
$cta-card-border: none;
$cta-card-opacity: 0.6;

$btn-font-size-mob: convert-to-rem(15);
$btn-font-size-desk: convert-to-rem(17);
$btn-anchor-active-outline: auto;
$btn-round-transform: none;
$btn-round-font-size-mob: convert-to-rem(16);
$btn-round-font-size-desk: convert-to-rem(18);
$btn-round-padding-desk: convert-to-rem(12);
$btn-gallery-font-size-desk: convert-to-rem(18);

/* || SIZEs */
$min-h-link-grid-mob: convert-to-rem(212);
$min-h-link-grid-desk: convert-to-rem(250);
$max-h-img-promo: convert-to-rem(690);
$h-icon: convert-to-rem(60);

/* || DISPLAYS */
$cityHotelsGap: convert-to-rem(25);
$guest-services-footer-gap: convert-to-rem(80);
$grid-featured-hotels-row-mob: convert-to-rem(121);
$grid-featured-hotels-row-desk: convert-to-rem(236);
$gap-nav-links-mob: convert-to-rem(15);
$gap-nav-links-desk: convert-to-rem(12);

/* || CAROUSELS */
$swiper-nav-hover-scale: none;

/* || HEADER */
$logo-w-mob: 132;
$logo-w-desk: 167;
$logo-h-mob: 39;
$logo-h-desk: 50;

/* || FORMS */
$invalid-box-shadow: 0px convert-to-rem(1) 0px 0px
  rgba(var(--c-alert-negative), 1);

/* || MAPS */
$map-border-radius: convert-to-rem(20);
$map-padding: convert-to-rem(5) convert-to-rem(14);
$map-label-margin: 5.25rem;

/* || ICONS */
$icon-acordion-size-mob: convert-to-rem(16);
$icon-acordion-size-desk: convert-to-rem(22);
$icon-guest-services-icon-size-mob: convert-to-rem(60);
$icon-guest-services-icon-size-desk: convert-to-rem(80);

/* || BLOG */
$blogHeading: headingFour;
$blogSubHeading: tagsBig;
$blog-italic: tagsSmall;
$ul-icon-width: convert-to-rem(22);
$ul-icon-height: convert-to-rem(22);
$blog-figcaption-opacity: 1;

/* || MY ACOOUNT */
$myAccountHeaderMobileFontSize: convert-to-rem(48) !default;
$myAccountHeaderMobileLineHeight: convert-to-rem(52) !default;

//image-with-large-links-split-component
$imageWithLargeLinksSplitComponentDesktopLeftGutter: 12vw !default;
