$c-primary: #273f2b;
$c-primary-60: #3e6545;
$c-green-light: #b9cdbb;
$c-dorian: #647789;

/* Neutrals */
$c-black: #000;
$c-white: #fff;
$c-ivory: #ebe0ce;
$c-ivory-105: #dfd5c4;
$c-ivory-70: #f3ece2;

/* Alerts */
$c-alert-negative: #c4683c;
$c-alert-inactive: convert-hex-to-rgb($c-primary);
$c-alert-neutral: $c-white;

/* Backdrop */
$c-backdrop: rgba($c-primary, 0.7);
$c-backdrop-reviews: rgba($c-primary, 0.9);

/* || WILDE THEMES */
[class*=" wilde--"] {
  --text-color: #{$c-black};
  --text-color-rgb: #{convert-hex-to-rgb($c-black)};
  --btn-primary-text-color: #{$c-ivory};
  --c-alert-inactive: #{rgba($c-primary, 0.4)};
  --c-alert-inactive-text: #{rgba($c-ivory, 0.8)};

  --btn-secondary-color: transparent;
  --btn-secondary-color-h: #{rgba($c-black, 0.05)};
  --btn-secondary-border-color: #{$c-black};
  --btn-secondary-text-color: #{$c-black};

  --btn-gallery-color: #{rgba($c-primary, 0.7)};
  --btn-gallery-color-h: #{rgba($c-black, 1)};
  --btn-gallery-text-color: #{$c-white};

  --btn-light-color: #{$c-primary};
  --btn-light-text-color: #{$c-ivory};

  --btn-round-primary-color: #{$c-black};
  --btn-round-primary-color-h: #{rgba($c-black, 0.4)};
  --btn-round-primary-text-color: #{$c-ivory};

  --btn-anchor-border-color: #{rgba($c-black, 0.1)};
  --btn-anchor-text-color: #{$c-black};
  --btn-anchor-color-h: #{rgba($c-black, 0.05)};
  --btn-anchor-border-color-h: #{rgba($c-black, 0.05)};
  --btn-anchor-active-outline: auto;

  --cta-lg-text-color-h: #{$c-primary-60};
  --cta-lg-text-color-active: #{rgba($c-primary-60, 0.4)};

  --cta-sm-text-color-h: #{$c-primary-60};
  --cta-sm-text-color-active: #{$c-primary-60};

  --swiper-nav-color-h: #{rgba($c-primary-60, 0.4)};

  --custom-dropdown-text-color: #{$c-black};
  --custom-dropdown-color: #{$c-ivory};
  --custom-dropdown-color-h: #{$c-ivory-105};

  --account-dropdown-h: #{$c-black};
  --account-dropdown-text-h: #{$c-ivory};

  /* Forms */
  --select-focus-outline: #{$c-green-light};

  --field-valid-highlight: #{$c-green-light};

  --field-border: #{$c-green-light};

  --checkbox-outer-border: #{$c-primary};
  --checkbox-inner-fill: #{$c-primary};
  --checkbox-inner-tick: #{$c-ivory};

  /* Map */
  --c-map-background: #{rgba($c-alert-negative, 0.7)};
  --c-map-label: #{$c-ivory};
  --c-map-label-bg: #{$c-black};
  --c-map-label-poi: #{$c-black};
  --c-map-label-bg-poi: #{rgba($c-ivory, 0.8)};

  /* Img loading placeholder */
  --c-video-thumbnail-icon: #{$c-ivory-70};

  /* Guest Services */
  --guest-services-cta-h: #{rgba($c-ivory, 0.15)};

  /* Reviews */
  --c-review-unfilled: #{rgba($c-black, 0.05)};

  /* Blog */
  --blog-ul-icon-color: #{$c-primary};
}

/* || IVORY */
.wilde--ivory {
  --background-color: #{$c-ivory};
  --text-color: #{$c-black};

  --custom-dropdown-color: #{$c-ivory-70};
}

/* || IVORY 70% */
.wilde--ivory-light {
  --background-color: #{$c-ivory-70};
  --text-color: #{$c-black};
}

.wilde--bg-ivory-70-mobile-only {
  @include media(screen0, $direction: max) {
    background-color: #{$c-ivory-70};
  }
}

/* || IVORY 70% ON SMALL SCREEN, 100% ON LARGE SCREEN */
.wilde--ivory-light-desk-100 {
  --background-color: #{$c-ivory-70};
  --text-color: #{$c-black};

  @include media(screen1024) {
    --background-color: #{$c-ivory};
    --text-color: #{$c-black};

    --custom-dropdown-color: #{$c-ivory-70};
  }
}

/* || IVORY 100% ON SMALL SCREEN, 70% ON LARGE SCREEN */
.wilde--ivory-desk-70 {
  --background-color: #{$c-ivory};
  --text-color: #{$c-black};

  --custom-dropdown-color: #{$c-ivory-70};

  @include media(screen1024) {
    --background-color: #{$c-ivory-70};
    --text-color: #{$c-black};
  }
}

/* || IVORY 105% */
.wilde--ivory-105 {
  --background-color: #{$c-ivory-105};
  --text-color: #{$c-black};
}

/* || IVORY 100% ON SMALL SCREEN, 105% ON LARGE SCREEN */
.wilde--ivory-desk-105 {
  --background-color: #{$c-ivory};
  --text-color: #{$c-black};

  --custom-dropdown-color: #{$c-ivory-70};

  @include media(screen1024) {
    --background-color: #{$c-ivory-105};
    --text-color: #{$c-black};
  }
}

/* || IVORY 105% ON SMALL SCREEN, 100% ON LARGE SCREEN */
.wilde--ivory-105-desk-100 {
  --background-color: #{$c-ivory-105};
  --text-color: #{$c-black};

  @include media(screen1024) {
    --background-color: #{$c-ivory};
    --text-color: #{$c-black};

    --custom-dropdown-color: #{$c-ivory-70};
  }
}

/* || WILDE ORANGE */
.wilde--orange {
  --background-color: #{$c-alert-negative};
  --text-color: #{$c-black};

  --btn-primary-color: #{$c-black};
  --btn-primary-color-h: #{rgba($c-black, 0.6)};

  --c-alert-inactive: #{rgba($c-black, 0.4)};

  --btn-round-primary-color: #{$c-ivory-70};
  --btn-round-primary-color-h: #{$c-ivory-105};
  --btn-round-primary-text-color: #{$c-black};

  --swiper-nav-color-h: #{rgba($c-ivory, 0.6)};
  --swiper-nav-color-active: #{$c-ivory};
}

/* || WILDE BLUE */
.wilde--blue {
  --background-color: #{$c-dorian};
  --text-color: #{$c-black};

  --cta-sm-text-color-h: #{rgba($c-ivory, 0.6)};

  --btn-primary-color: #{$c-black};
  --btn-primary-color-h: #{rgba($c-black, 0.6)};

  --c-alert-inactive: #{rgba($c-black, 0.4)};

  --btn-round-primary-color: #{$c-ivory-70};
  --btn-round-primary-color-h: #{$c-ivory-105};
  --btn-round-primary-text-color: #{$c-black};

  --swiper-nav-color-h: #{rgba($c-ivory, 0.6)};
  --swiper-nav-color-active: #{$c-ivory};
}

/* || WILDE GREEN */
.wilde--green {
  --background-color: #{$c-primary};
  --text-color: #{$c-ivory};
  --text-color-rgb: #{convert-hex-to-rgb($c-ivory)};

  --btn-primary-color: #{$c-black};
  --btn-primary-color-h: #{rgba($c-black, 0.6)};
  --btn-primary-text-color: #{$c-ivory};

  --c-alert-inactive: #{rgba($c-black, 0.4)};

  --btn-secondary-text-color: #{$c-ivory};

  --btn-round-primary-color: #{$c-ivory};
  --btn-round-primary-color-h: #{$c-ivory-105};
  --btn-round-primary-text-color: #{$c-primary};

  --btn-anchor-border-color: #{rgba($c-white, 0.1)};
  --btn-anchor-text-color: #{$c-ivory};
  --btn-anchor-color-h: #{rgba($c-white, 0.05)};
  --btn-anchor-border-color-h: #{rgba($c-white, 0.05)};

  --swiper-nav-color-h: #{rgba($c-ivory, 0.4)};
  --swiper-nav-color-active: #{rgba($c-ivory, 0.4)};

  &-60-copy {
    --text-color: #{$c-primary-60};
    --text-color-rgb: #{convert-hex-to-rgb($c-primary-60)};
  }

  &-copy {
    --text-color: #{$c-primary};
    --text-color-rgb: #{convert-hex-to-rgb($c-primary)};
  }
}

.wilde--white-fade {
  --background-color: #{rgba($c-white, 0.1)};
}

/* || WILDE HALF IMAGE HEADER LEFT */
.wilde--half-header-left {
  --text-color: #{$c-ivory};
  --text-color-rgb: #{convert-hex-to-rgb($c-ivory)};

  --btn-secondary-text-color: #{$c-black};

  --btn-round-primary-color: #{$c-black};
  --btn-round-primary-color-h: #{rgba($c-black, 0.4)};
  --btn-round-primary-text-color: #{$c-ivory};
}

/* || WILDE HALF IMAGE HEADER RIGHT */
.wilde--half-header-right {
  --text-color: #{$c-black};
  --text-color-rgb: #{convert-hex-to-rgb($c-black)};

  --btn-secondary-text-color: #{$c-ivory};

  --btn-round-primary-color: #{$c-ivory-70};
  --btn-round-primary-color-h: #{$c-ivory-105};
  --btn-round-primary-text-color: #{$c-black};
}
