/* || BLOCKS */
.display {
  &--b {
    display: block;
  }
  &--ib {
    display: inline-block;
  }
  &--n {
    display: none;
  }
}

/* || GRID */
.grid {
  display: grid;

  &-auto-flow {
    &--column {
      grid-auto-flow: column;
    }
    &--dense {
      grid-auto-flow: dense;
    }
  }

  &-auto-columns {
    &--1 {
      grid-auto-columns: 1fr;
    }
  }

  /* Grid Template Columns */
  &-col {
    &--2 {
      grid-template-columns: repeat(2, 1fr);
    }
    &--1-2 {
      grid-template-columns: 1fr;
      @include media(screen1024) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    &--1-3 {
      grid-template-columns: 1fr;
      @include media(screen1024) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }
    &--1-3-max {
      grid-template-columns: 1fr;
      @include media(screen1024) {
        grid-template-columns: max-content auto auto;
      }
    }
    &--2-4 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      @include media(screen1152) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
    }
    &--3-5 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      @include media(screen1152) {
        grid-template-columns: repeat(5, minmax(0, 1fr));
      }
    }
    &--3-6 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      @include media(screen1152) {
        grid-template-columns: repeat(6, minmax(0, 1fr));
      }
    }
    &--3-8 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      @include media(screen1024) {
        grid-template-columns: repeat(8, minmax(0, 1fr));
      }
    }
  }
  &--icon-info {
    /* 2 columns on mobile, however would need to be 1 column for very narrow screens (Galaxy fold). 40% stops it from ever going to 3 column with 1 below */
    grid-template-columns: repeat(auto-fit, minmax(40%, convert-to-rem(130)));
    /* all in one row on desktop */
    @include media(screen0) {
      grid-template-columns: initial;
      grid-auto-flow: column;
      grid-auto-columns: minmax(min-content, convert-to-rem(262));
    }
  }
  &--gallery-property {
    grid-template-columns: 60% 20% 20%;
    grid-template-rows: repeat(2, convert-to-rem(218));

    & .grid-item:first-child {
      grid-row: span 2;
    }
  }
  &--gallery-city {
    grid-template-columns: 60% 20% 20%;

    & .grid-item:first-child {
      grid-row: span 2;
    }
  }
  &--gallery-modal {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: convert-to-rem(109);

    & > *:nth-child(3n + 1) {
      grid-column: span 2;
      grid-row: span 2;
    }

    @include media(screen0) {
      grid-auto-rows: convert-to-rem(324);
    }
  }
  &--city-hotel-info {
    grid-template-columns: 1fr;
    @include media(screen1024) {
      grid-template-columns: 1.65fr 1fr;
    }
  }
  &--property-info {
    grid-template-columns: 1fr;
    @include media(screen1024) {
      grid-template-columns: 1fr 1.5fr;
    }
  }
  &--city-facilities {
    grid-auto-columns: convert-to-rem(80);

    @include media(screen0) {
      grid-template-columns: repeat(8, minmax(0, 1fr));
    }
  }
  &--feature-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: $grid-featured-hotels-row-mob;

    @include media(screen1024) {
      grid-template-columns: repeat(5, 1fr);
      grid-auto-rows: $grid-featured-hotels-row-desk;
    }
  }

  /* Grid Column Spans */
  &-col-span--2 {
    grid-column: span 2;
  }
  &-col-span--1-2 {
    grid-column: span 1;
    @include media(screen1024) {
      grid-column: span 2;
    }
  }

  /* Grid Row Spans */
  &-row-span--2 {
    grid-row: span 2;
  }
}

/* || FLEX */
.flex {
  display: flex;

  &-desktop {
    @include media(screen1024) {
      display: flex;
    }

    &--0-0 {
      &-75 {
        @include media(screen1024) {
          flex: 0 0 75%;
        }
      }

      &-50 {
        @include media(screen1024) {
          flex: 0 0 50%;
        }
      }
    }
  }

  &-wrap {
    flex-wrap: wrap;
  }
  &-nowrap {
    flex-wrap: nowrap;
  }
  &-shrink {
    &--0 {
      flex-shrink: 0;
    }
  }
  &-grow {
    &--1 {
      flex-grow: 1;
    }
    &--0 {
      flex-grow: 0;
    }
    &--1 {
      flex-grow: 1;
    }
  }
  &--0-0-33 {
    flex: 0 0 33%;
  }
  &--50 {
    flex: 0.5;
  }
  &--75 {
    flex: 0.75;
  }
  &--1 {
    flex: 1;
  }
  &--1-5 {
    flex: 1.5;
  }
  &--7-1 {
    flex: 0.7;
    @include media(screen1024) {
      flex: 1;
    }
  }

  /* Flex Direction */
  &-dir {
    &--column {
      flex-direction: column;
    }
    &--column-row {
      flex-direction: column;
      @include media(screen1024) {
        flex-direction: row;
      }
    }
    &--column-reverse-row {
      flex-direction: column;
      @include media(screen1024) {
        flex-direction: row-reverse;
      }
    }
    &--reverse-column-row {
      flex-direction: column-reverse;
      @include media(screen1024) {
        flex-direction: row;
      }
    }
  }
}

/* || GRID / FLEX ITEMS */
.justify-content {
  &--center {
    justify-content: center;
  }
  &--space-between {
    justify-content: space-between;
  }
  &--space-evenly {
    justify-content: space-evenly;
  }
  &--flex-end {
    justify-content: flex-end;
  }
  &--start-center {
    justify-content: start;
    @include media(screen1024) {
      justify-content: center;
    }
  }
  &--end-center {
    justify-content: end;
    @include media(screen1024) {
      justify-content: center;
    }
  }
  &--center-start {
    justify-content: center;
    @include media(screen1024) {
      justify-content: start;
    }
  }
  &--center-end {
    justify-content: center;
    @include media(screen1024) {
      justify-content: end;
    }
  }
  &--space-between-start {
    justify-content: space-between;
    @include media(screen1024) {
      justify-content: start;
    }
  }
  &--space-start-end {
    justify-content: flex-start;
    @include media(screen1024) {
      justify-content: flex-end;
    }
  }
}

.align-items {
  &--baseline {
    align-items: baseline;
  }
  &--center {
    align-items: center;
  }
  &--start {
    align-items: start;
  }
  &--end {
    align-items: end;
  }
  &--flex-start {
    align-items: flex-start;
  }
  &--flex-end {
    align-items: flex-end;
  }
  &--start-center {
    align-items: start;
    @include media(screen1024) {
      align-items: center;
    }
  }
}

.justify-items {
  &--start {
    justify-items: start;
  }
}

/* || ORDER */
.order {
  &--1 {
    order: 1;
  }
  &--0-minus-1 {
    order: 0;
    @include media(screen1024) {
      order: -1;
    }
  }
}

/* || GAP */
.gap {
  gap: 0.5rem;

  &--xs {
    gap: 0.25rem;
  }
  &--sm {
    gap: 0.75rem;
  }
  &--md {
    gap: 1rem;
  }
  &--lg {
    gap: 1.5rem;
  }
  &--0-gap {
    gap: 0;
    @include media(screen1024) {
      gap: 0.5rem;
    }
  }
  &--xs-gap {
    gap: 0.25rem;
    @include media(screen1024) {
      gap: 0.5rem;
    }
  }
  &--sm-md {
    gap: 0.75rem;
    @include media(screen1024) {
      gap: 1rem;
    }
  }
  &--header {
    gap: 1rem;
    @include media(screen1024) {
      gap: convert-to-rem(29);
    }
  }
  &--nav {
    gap: convert-to-rem(35);
    @include media(screen1024) {
      gap: convert-to-rem(83);
    }
  }
  &--nav-cities {
    column-gap: 1rem;
    row-gap: 1.25rem;
    @include media(screen0) {
      column-gap: convert-to-rem(47);
    }
  }
  &--nav-links {
    gap: $gap-nav-links-mob;
    @include media(screen1024) {
      gap: $gap-nav-links-desk;
    }
  }
  &--nav-small-links {
    gap: convert-to-rem(15);
    @include media(screen0) {
      gap: convert-to-rem(10);
    }
  }
  &--simple-text {
    gap: convert-to-rem(35);
  }
  &--cards {
    gap: convert-to-rem(12);
    @include media(screen1024) {
      gap: convert-to-rem(34);
    }
  }
  &--icon-info {
    gap: convert-to-rem(44);
  }
  &--city-header {
    gap: 0;
    @include media(screen1024) {
      gap: convert-to-rem(50);
    }
  }
  &--city-intro {
    row-gap: convert-to-rem(18);
    @include media(screen1024) {
      row-gap: 0;
      column-gap: convert-to-rem(65);
    }
  }
  &--city-hotels {
    gap: $cityHotelsGap;
    @include media(screen1024) {
      gap: 0;
    }
  }
  &--city-hotel-info {
    gap: convert-to-rem(55);
    @include media(screen1024) {
      gap: convert-to-rem(40);
    }
  }
  &--hotel-facilities {
    column-gap: convert-to-rem(12);
    row-gap: convert-to-rem(30);
    @include media(screen1024) {
      gap: convert-to-rem(30);
    }
  }
  &--hotel-info {
    gap: convert-to-rem(25);
    @include media(screen1024) {
      gap: convert-to-rem(35);
    }
  }
  &--footer-top-section {
    gap: convert-to-rem(83);
    @include media(screen1024) {
      gap: convert-to-rem(35);
    }
  }
  &--footer-links {
    gap: convert-to-rem(40);
    @include media(screen1024) {
      gap: convert-to-rem(35);
    }
  }
  &--sub-footer {
    gap: convert-to-rem(30);
    @include media(screen1024) {
      gap: convert-to-rem(60);
    }
  }
  &--sub-footer-links {
    gap: convert-to-rem(10);
    @include media(screen1024) {
      gap: convert-to-rem(25);
    }
  }
  &--feature-grid {
    column-gap: convert-to-rem(13);
    row-gap: convert-to-rem(33);
    @include media(screen1024) {
      column-gap: convert-to-rem(26);
    }
  }
  &--alternative-details {
    gap: convert-to-rem(57);
  }
  &--guest-services-ctas {
    gap: convert-to-rem(14);
    @include media(screen1024) {
      gap: convert-to-rem(35);
    }
  }
  &--guest-services-cta {
    gap: convert-to-rem(25);
    @include media(screen1024) {
      gap: convert-to-rem(30);
    }
  }
  &--guest-services-footer {
    gap: $guest-services-footer-gap;
    @include media(screen1024) {
      gap: 0;
    }
  }
  &--reviews-overlay-ctas {
    gap: convert-to-rem(15);
    @include media(screen1024) {
      gap: convert-to-rem(20);
    }
  }
  &--blog-imgs {
    gap: convert-to-rem(64);
    @include media(screen1024) {
      gap: convert-to-rem(35);
    }
  }
  &--blog-listings {
    column-gap: convert-to-rem(29);
    row-gap: convert-to-rem(50);
    @include media(screen1024) {
      row-gap: convert-to-rem(70);
    }
  }
  &--blog-categories {
    column-gap: 1rem;
    row-gap: 0.5rem;
  }
  &--error-page {
    gap: convert-to-rem(202);
    @include media(screen1024) {
      gap: convert-to-rem(80);
    }
  }
}
