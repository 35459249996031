/* || SCREENS */
.only {
  &--mobile-0 {
    display: block;
    @include media(screen0) {
      display: none;
    }
  }
  &--mobile-1024 {
    display: block;
    @include media(screen1024) {
      display: none;
    }
  }
  &--desktop-0 {
    display: none;
    @include media(screen0) {
      display: block;
    }
  }
  &--desktop-1024 {
    display: none;
    @include media(screen1024) {
      display: block;
    }
  }
}

.hidden {
  display: none;
  &--important {
    display: none !important;
  }
}

/* || HRs */
.hr {
  border: none;
  border-top: convert-to-rem(1) solid rgba(var(--text-color-rgb), 0.15);

  &--solid {
    border-top: convert-to-rem(1) solid rgba(var(--text-color-rgb), 1);
  }
}

/* || Lists */
.list-style {
  &--none {
    list-style-type: none;
  }
}

/* || Strikethough */
.strikethrough {
  position: relative;
  &:after {
    content: "";
    width: 100%;
    height: convert-to-rem(3);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: $c-primary;
  }
}

/* Makes img / container full width, ignoring parent margins. Eg. on the city page mobile galleries */
.full-width {
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  &--sm {
    position: relative;
    @include media(screen0) {
      width: 100%;
      position: initial;
      margin: 0;
    }
  }

  &--lg {
    position: relative;
    @include media(screen1024) {
      width: 100%;
      position: initial;
      margin: 0;
    }
  }
}

/* remove default <a> tag styles when not using a preset cta class */
.a-reset {
  color: inherit;
  text-decoration: none;
}

/* || Cursors */
.cursor {
  &--pointer {
    cursor: pointer;
  }
}

/* || Overflow */
/* overflow-x--hidden is used on <main> tag to prevent side scrolling on pages which include carousels that bleed off the side of the page */
.overflow {
  &--inherit {
    overflow: inherit;
    &--mob-only {
      @include media(screen0, $direction: max) {
        overflow: inherit;
      }
    }
  }
  &--hidden {
    &-x {
      overflow-x: hidden;
    }
    overflow: hidden;
    &--mob {
      &--x {
        @include media(screen0, $direction: max) {
          overflow-x: hidden;
        }
      }
    }
  }
  &--auto {
    &-x {
      overflow-x: auto;
    }
    &-y {
      overflow-y: auto;
    }
  }
  &--scroll {
    &-y {
      overflow-y: scroll;
    }
    &-x {
      overflow-x: scroll;
    }
  }
  &--visible {
    overflow: visible;
    &-x {
      overflow-x: visible;
    }
    &-y {
      overflow-y: visible;
    }
  }
  &--ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &--visible-hidden {
    overflow: visible;
    @include media(screen1024) {
      overflow: hidden;
    }
  }
}

.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.hide-scrollbar::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

/* || Z-index */
.z-index {
  &--minus-1 {
    z-index: -1;
  }
  &--0 {
    z-index: 0;
  }
  &--1 {
    z-index: 1;
  }
  &--2 {
    z-index: 2;
  }
  &--3 {
    z-index: 3;
  }
  &--4 {
    z-index: 4;
  }
  &--5 {
    z-index: 5;
  }
  &--8 {
    z-index: 8;
  }
  &--9 {
    z-index: 9;
  }
  &--10 {
    z-index: 10;
  }
  &--11 {
    z-index: 11;
  }
  &--20 {
    z-index: 20;
  }
}

/* || Opacity */
.opacity {
  &--4 {
    opacity: 0.4;
  }
  &--6 {
    opacity: 0.6;
  }
  &--7 {
    opacity: 0.7;
  }
  &--8 {
    opacity: 0.8;
  }
}

/* || TRANSFORM */
.rotate {
  &--90 {
    transform: rotate(90deg);
  }
  &--180 {
    transform: rotate(180deg);
  }
}
.transform {
  &--center {
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}

/* || BACKDROP */
.backdrop {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: var(--c-backdrop);
  opacity: 0;

  &-active {
    left: 0;
    opacity: 1;
  }

  &--reviews {
    background-color: var(--c-backdrop-reviews);
  }
}

.modal {
  opacity: 0;
  &-active {
    opacity: 1;
  }
}

/* || SVG UNDERLINE */
.svg-underline {
  position: relative;
  width: fit-content;
  display: inline-block;

  &::after {
    content: "";
    display: $display-only-wilde;
    position: absolute;
    bottom: -0.5rem;
    left: 0;
    right: -0.5rem;
    height: convert-to-rem(7);
    z-index: -1;
    background-color: currentColor;
    mask-image: url("../assets/icons/wilde-underline.svg");
    mask-size: auto 100%;
    mask-repeat: repeat-x;
    mask-position: left;

    @include media(screen1024) {
      bottom: convert-to-rem(-12);
    }
  }

  &--view-booking {
    &::after {
      background-color: #{$c-primary};
      height: convert-to-rem(3);
      mask-repeat: no-repeat;
      mask-size: 100%;
    }
  }
}

/* Adjust size of svg underline when used in smaller text fields */
.t-tags-small .svg-underline,
.t-tags-big-small .svg-underline,
.t-tags-big .svg-underline {
  &::after {
    height: convert-to-rem(3);
    bottom: 0;
  }
}

.svg-underline-animate {
  .svg-underline::after {
    animation-duration: 1.5s;
    animation-timing-function: $carousel-transition;
    animation-name: svgSlideIn;
    animation-delay: 3s;
    animation-fill-mode: forwards;
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
  }

  &--city {
    .svg-underline::after {
      animation-delay: 1.5s;
    }
  }

  &--view-booking {
    .svg-underline::after {
      animation-delay: 0.5s;
    }
  }
}

@keyframes svgSlideIn {
  from {
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
  }

  to {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

.search-bar-positioning {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 5vw;
  max-width: convert-to-rem(940);
  width: calc(100% - 10vw);
  padding: 0;
  @include media(screen1024) {
    padding: 0 convert-to-rem(20);
  }
  @media (min-height: 801px) and (min-width: 64em) {
    bottom: 25vh;
  }
  &--full-width {
    width: 100%;
  }
  &--snackbar-adjustment {
    .snackbar-active & {
      @include media(screen1024) {
        bottom: 20vh;
      }
    }
  }
  &--to-mobile-edges {
    @include media(screen0, $direction: max) {
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      transform: none;
      #app-container__app {
        div[class^="SearchDetailsStyled"] {
          border-radius: 0;
        }
      }
    }
  }
  &--single-feature-image-header-component--wilde.search-bar-positioning--snackbar-adjustment {
    @include media(screen1024) {
      bottom: 25vh;
    }
    @media (min-width: 1400px) {
      bottom: 35vh;
    }
    .snackbar-active & {
      @media (min-width: 1400px) {
        bottom: 40vh;
      }
      @media (min-width: 1600px) {
        bottom: 45vh;
      }
    }
  }
}

.city-header-search-bar {
  position: absolute;
  bottom: convert-to-rem(25);
  width: 100%;
  @include media(screen1024) {
    position: relative;
    bottom: 0;
  }
}

.city-page-search-bar-wilde {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 5vh;
  max-width: convert-to-rem(940);
  width: calc(100% - 10vw);
  padding: 0;
  @include media(screen1024) {
    padding: 0 convert-to-rem(20);
    bottom: 25vh;
  }
}

.hotel-page-search-bar-wilde {
  position: absolute;
  bottom: convert-to-rem(25);
  left: 0;
  width: 100%;
  padding: 0 convert-to-rem(20);
  @include media(screen1024) {
    bottom: 25vh;
    left: -100%;
    transform: translateX(25%);
    width: 200%;
    max-width: convert-to-rem(940);
  }
}

.city-header-search-bar-position {
  position: relative;
  @include media(screen1024) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5vh;
    max-width: convert-to-rem(940);
    width: calc(100% - 10vw);
    bottom: 25vh;
    padding: 0 convert-to-rem(20);
    z-index: 2;
  }
}

.box-sizing {
  &--content-box {
    box-sizing: content-box;
  }
}

.shadow {
  &--menu-dropdown {
    box-shadow: 0 0 convert-to-rem(15) 0 #{rgba($c-black, 0.1)};
  }
}

.my-account-divider-line {
  @include media(screen1024) {
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: convert-to-rem(30);
      top: 0;
      height: calc(100% - convert-to-rem(20));
      width: convert-to-rem(1);
      background-color: rgba($c-black, 0.1);
    }
  }
}

.my-account-section-header {
  margin: convert-to-rem(16) 0 convert-to-rem(48) 0;
  @include media(screen1024) {
    margin: convert-to-rem(35) 0 convert-to-rem(24) 0;
  }
  @include media(screen0, $direction: max) {
    .svg-underline:after {
      z-index: 0;
    }
  }
}

.mobile-section-divider {
  display: block;
  width: 100%;
  height: convert-to-rem(12);
  border-top: 1px solid rgba($mobile-section-divider-border, 0.05);
  border-bottom: 1px solid rgba($mobile-section-divider-border, 0.05);
  background-color: rgba($mobile-section-divider-border, 0.05);
  &--no-top {
    border-top: none;
  }
}

.my-account-header {
  @include media(screen0, $direction: max) {
    h2 {
      font-size: $myAccountHeaderMobileFontSize;
      line-height: $myAccountHeaderMobileLineHeight;
    }
  }
}

.account-overlay-header {
  @include media(screen1024) {
    h3 {
      font-size: convert-to-rem(36);
    }
  }
}

.hotel-banner-strip {
  padding-top: convert-to-rem(5);
  padding-bottom: convert-to-rem(5);
  margin-top: convert-to-rem(65);
  img {
    width: convert-to-rem(87);
    height: convert-to-rem(87);
  }
  @include media(screen1024) {
    margin-top: convert-to-rem(80);
  }
}

.opening-times-and-contact-details {
  &__header {
    margin-bottom: convert-to-rem(30);
    @include media(screen1024) {
      margin-bottom: convert-to-rem(60);
    }
    &__text {
      font-size: convert-to-rem(46);
      line-height: convert-to-rem(53);
      @include media(screen1024) {
        font-size: convert-to-rem(62);
        line-height: convert-to-rem(62);
      }
    }
  }
  &__items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include media(screen1024) {
      flex-direction: row;
    }
    &__item {
      margin-bottom: convert-to-rem(34);
      @include media(screen1024) {
        width: calc(100% / 3 - convert-to-rem(16));
        margin: 0;
      }
      &__header {
        margin-bottom: convert-to-rem(16);
        font-size: convert-to-rem(28);
        line-height: convert-to-rem(32);
        font-family: Mediaan-Italic, serif;
        @include media(screen1024) {
          font-size: convert-to-rem(34);
          line-height: convert-to-rem(41);
        }
      }
      &__sub-header {
        font-size: convert-to-rem(18);
        line-height: convert-to-rem(28);
        font-family: Mediaan-Italic, serif;
        @include media(screen1024) {
          font-size: convert-to-rem(20);
          line-height: convert-to-rem(31);
        }
      }
      &__copy {
        margin-bottom: convert-to-rem(16);
        font-size: convert-to-rem(18);
        line-height: convert-to-rem(28);
        @include media(screen1024) {
          font-size: convert-to-rem(20);
          line-height: convert-to-rem(31);
        }
      }
    }
  }
}

.image-with-large-links-split-component {
  > div {
    @include media(screen0, $direction: max) {
      flex-direction: column-reverse;
    }
  }
  &__text {
    padding: convert-to-rem(30) convert-to-rem(20);
    @include media(screen1024) {
      padding: convert-to-rem(70) convert-to-rem(70) convert-to-rem(70)
        $imageWithLargeLinksSplitComponentDesktopLeftGutter;
    }
    &__links {
      margin-top: convert-to-rem(20);
      margin-bottom: convert-to-rem(40);
      @include media(screen1024) {
        margin-bottom: convert-to-rem(80);
      }
      ol {
        li {
          margin: 0;
          & + li {
            margin-top: convert-to-rem(20);
          }
        }
      }
    }
  }
  &__image {
    @include media(screen1024) {
      aspect-ratio: 1;
    }
  }
}

.featured-hotel-container-component__card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &__card {
    width: calc(50% - convert-to-rem(8));
    margin-bottom: convert-to-rem(20);
    @include media(screen1024) {
      width: calc(25% - convert-to-rem(16));
    }
  }
}
