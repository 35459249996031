/* || GRID COLOURS / THEMES */
/* These are set as CSS variables as these can be easily overwritten within a class and apply to any children properties */
:root,
.staycity--white {
  --background-color: #{$c-white};
  --background-gradient: "none";
  --text-color: #{$c-black};
  --text-color-rgb: #{convert-hex-to-rgb($c-black)}; // rgb version is used for setting opacity for borders and hover effects. rgba() doesn't work with css vars as hex

  /* Cta / Btn colours */
  --c-alert-positive: #{$c-alert-positive};
  --c-alert-positive-h: #{$c-alert-positive-h};
  --c-alert-inactive: #{$c-alert-inactive};
  --c-alert-inactive-text: #{rgba($c-black, 0.4)};
  --c-alert-negative: #{convert-hex-to-rgb($c-alert-negative)};
  --btn-primary-color: #{$c-alert-positive};
  --btn-primary-color-h: #{$c-alert-positive-h};
  --btn-primary-text-color: #{$c-black};

  --btn-secondary-color: #{$c-black};
  --btn-secondary-border-color: #{$c-black};
  --btn-secondary-color-h: #{rgba($c-black, 0.6)};
  --btn-secondary-text-color: #{$c-white};

  --btn-gallery-color: #{rgba($c-black, 0.6)};
  --btn-gallery-color-h: #{rgba($c-black, 1)};
  --btn-gallery-text-color: #{$c-white};

  --btn-light-color: #{$c-alert-positive};
  --btn-light-color-h: #{$c-alert-positive-h};
  --btn-light-text-color: #{$c-black};

  --btn-dark-color: #{$c-black};
  --btn-dark-color-h: #{rgba($c-black, 0.6)};
  --btn-dark-text-color: #{$c-white};

  --btn-round-primary-color: #{$c-black};
  --btn-round-primary-color-h: #{rgba($c-black, 0.6)};
  --btn-round-primary-text-color: #{$c-white};

  --btn-anchor-border-color: rgba(var(--text-color-rgb), 0.2);
  --btn-anchor-text-color: var(--text-color);
  --btn-anchor-color-h: #{$c-alert-positive};
  --btn-anchor-border-color-h: #{$c-alert-positive-h};
  --btn-anchor-light-border-color: #{$c-white};

  --cta-lg-text-color-h: var(--text-color);
  --cta-lg-text-color-active: #{$c-black-70};

  --cta-sm-text-color-h: #{$c-alert-positive};
  --cta-sm-text-color-active: #{$c-black-70};

  --swiper-nav-color-h: var(--btn-primary-color);
  --swiper-nav-color-active: var(--btn-primary-color-h);

  --custom-dropdown-text-color: #{$c-black};
  --custom-dropdown-color: #{$c-white};
  --custom-dropdown-color-h: #{$c-white-105};

  --account-dropdown-h: #{$c-white-105};
  --account-dropdown-text-h: #{$c-black};

  /* Forms */
  --select-focus-outline: #{$c-white-110};

  --checkbox-outer-border: #{$c-black};
  --checkbox-inner-fill: #{$c-primary};
  --checkbox-inner-tick: #{$c-black};

  --field-valid-highlight: rgba(var(--text-color-rgb), 0.15);

  --field-border: rgba(var(--text-color-rgb), 0.15);

  /* Backdrop */
  --c-backdrop: #{$c-backdrop};
  --c-backdrop-reviews: #{$c-backdrop-reviews};

  /* Map */
  --c-map-background: #{$c-backdrop};
  --c-map-label: #{$c-black};
  --c-map-label-bg: #{rgba($c-white, 0.8)};
  --c-map-label-poi: #{$c-black};
  --c-map-label-bg-poi: #{rgba($c-white, 0.8)};

  /* Img loading placeholder */
  --c-img-placeholder: #{convert-hex-to-rgb($c-black), 0.05};
  --c-video-thumbnail-icon: #{$c-white};

  /* Guest Services */
  --guest-services-cta-h: #{$c-white};

  /* Reviews */
  --c-review-unfilled: #{rgba($c-black, 0.3)};

  /* Blog */
  --blog-ul-icon-color: rgba(#{convert-hex-to-rgb($c-paris), 0.5});

  --c-promo-card-asterisk: #{rgba($c-black, 0.004)};
  --c-promo-card-cta-h: #{$c-black};
  --c-promo-card-cta-text-h: #{$c-primary};
  /* Icon changes depending on promo card theme */
  --c-promo-card-asterisk-icon: url("../assets/icons/asterisk1.svg");
}

/* || WHITE / OFF-WHITE */
.staycity--white {
  --text-color: #{$c-black};

  --custom-dropdown-color: #{$c-white-105};
  --custom-dropdown-color-h: #{$c-white-110};
}

/* || WHITE 105% / OFF-WHITE 105% */
.staycity--white-105 {
  --background-color: #{$c-white-105};
  --text-color: #{$c-black};
}

/* || WHITE ON SMALL SCREEN, WHITE 105% ON LARGE SCREEN */
.staycity--white-desk-105 {
  --background-color: #{$c-white};
  --text-color: #{$c-black};

  --custom-dropdown-color: #{$c-white-105};
  --custom-dropdown-color-h: #{$c-white-110};

  @include media(screen1024) {
    --background-color: #{$c-white-105};
    --text-color: #{$c-black};
  }
}

/* || WHITE 105% ON SMALL SCREEN, WHITE 100% ON LARGE SCREEN */
.staycity--white-105-desk-100 {
  --background-color: #{$c-white-105};
  --text-color: #{$c-black};

  @include media(screen1024) {
    --background-color: #{$c-white};
    --text-color: #{$c-black};

    --custom-dropdown-color: #{$c-white-105};
    --custom-dropdown-color-h: #{$c-white-110};
  }
}

/* || FLAT GREY */
.staycity--grey {
  --background-color: #{$c-white-130};
  --text-color: #{$c-black};
}

/* || GREY TO WHITE */
.staycity--grey-white {
  --background-color: #{$c-white-130};
  --background-gradient: linear-gradient(#{$c-white-130} 0%, #{$c-white} 100%);
  --text-color: #{$c-black};
}

/* || DUBLIN YELLOW */
.staycity--yellow {
  --background-color: #{$c-primary};
  --text-color: #{$c-black};

  --c-alert-positive: #{$c-black-70};
  --cta-sm-text-color-h: #{rgba($c-black, 0.6)};
  --btn-primary-color: #{$c-black};
  --btn-primary-color-h: #{$c-black-70};
  --btn-primary-text-color: #{$c-white};

  --c-img-placeholder: #{convert-hex-to-rgb($c-white), 0.3};
}

/* || FRANKFURT ORANGE */
.staycity--orange {
  --background-color: #{$c-primary-dark};
  --text-color: #{$c-black};

  --c-alert-positive: #{$c-black-70};
  --cta-sm-text-color-h: #{rgba($c-black, 0.6)};
  --btn-primary-color: #{$c-black};
  --btn-primary-color-h: #{$c-black-70};
  --btn-primary-text-color: #{$c-white};

  --c-img-placeholder: #{convert-hex-to-rgb($c-white), 0.3};
}

/* || LIGHT TO DARK ORANGE GRADIENT */
.staycity--orange-gradient {
  --background-color: #{$c-primary};
  --background-gradient: linear-gradient(
    #{$c-primary} 0%,
    #{$c-primary-dark} 100%
  );
  --text-color: #{$c-black};

  --c-alert-positive: #{$c-black-70};
  --cta-sm-text-color-h: #{rgba($c-black, 0.6)};
  --btn-primary-color: #{$c-black};
  --btn-primary-color-h: #{$c-black-70};
  --btn-primary-text-color: #{$c-white};

  --c-img-placeholder: #{convert-hex-to-rgb($c-white), 0.3};
}

/* DARK TO LIGHT ORANGE GRADIENT */
.staycity--dark-light-orange {
  --background-color: #{$c-primary-dark};
  --background-gradient: linear-gradient(
    #{$c-primary-dark} 0%,
    #{$c-primary} 100%
  );
  --text-color: #{$c-black};

  --c-alert-positive: #{$c-black-70};
  --btn-primary-color: #{$c-black};
  --btn-primary-color-h: #{$c-black-70};
  --btn-primary-text-color: #{$c-white};
}

/* FLAT DARK ORANGE */
.staycity--dark-orange {
  --background-color: #{$c-primary-dark};
  --text-color: #{$c-black};

  --c-alert-positive: #{$c-black-70};
  --btn-primary-color: #{$c-black};
  --btn-primary-color-h: #{$c-black-70};
  --btn-primary-text-color: #{$c-white};
}

/* FLAT ORANGE ON BLACK */
.staycity--orange-dark {
  --background-color: #{$c-black};
  --text-color: #{$c-primary-dark};
}

/* || PINK */
.staycity--pink {
  --background-color: #{$c-edinburgh};
  --text-color: #{$c-black};

  --cta-sm-text-color-h: #{rgba($c-black, 0.6)};
}

/* || PINK TO GREY GRADIENT */
.staycity--pink-grey {
  --background-color: #{$c-edinburgh};
  --background-gradient: linear-gradient(
    0deg,
    #{$c-white-130} 17.71%,
    transparent 61.46%
  );
  --text-color: #{$c-black};
}

/* || BLUE */
.staycity--blue {
  --background-color: #{$c-marseille};
  --text-color: #{$c-white};
  --text-color-rgb: #{convert-hex-to-rgb($c-white)};
}

/* || BLACK / DARK GREY */
.staycity--dark {
  --background-color: #{$c-black};
  --text-color: #{$c-white};
  --text-color-rgb: #{convert-hex-to-rgb($c-white)};

  --btn-secondary-color: #{$c-alert-positive};
  --btn-secondary-border-color: #{$c-alert-positive};
  --btn-secondary-color-h: #{$c-alert-positive-h};
  --btn-secondary-text-color: #{$c-alert-primary-text};

  --btn-round-primary-color: #{$c-alert-positive};
  --btn-round-primary-color-h: #{$c-alert-positive-h};
  --btn-round-primary-text-color: #{$c-alert-primary-text};

  --c-promo-card-asterisk: #{rgba($c-white, 0.006)};
  --c-promo-card-asterisk-icon: url("../assets/icons/asterisk3.svg");
  --c-promo-card-cta-h: #{$c-white};
  --c-promo-card-cta-text-h: #{$c-black};
}

.staycity--faded-black {
  --background-color: #{rgba($c-black, 0.05)};
  --text-color: #{$c-black};
}

/* || BLACK (70%) */
.staycity--black-70 {
  --text-color: #{$c-black-70};
  --text-color-rgb: #{convert-hex-to-rgb($c-black-70)};
}

.bg--default {
  --background-color: #{$c-white};
  --text-color: #{$c-black};
}

/* Set background and text colour on theme container */
.bg--theme {
  background-color: var(--background-color);
  background-image: var(--background-gradient);
  color: var(--text-color);

  &-card {
    background-color: var(--background-color);
    color: var(--text-color);
  }

  &-text {
    color: var(--text-color);
  }

  &-text-mobile-only {
    color: var(--text-color);

    @include media(screen1024) {
      color: inherit;
    }
  }

  &-mobile {
    background-color: var(--background-color);
    background-image: var(--background-gradient);
    color: var(--text-color);

    @include media(screen1024) {
      background-color: inherit;
      background-image: inherit;
      color: inherit;
    }
  }

  &-desktop {
    @include media(screen1024) {
      background-color: var(--background-color);
      background-image: var(--background-gradient);
      color: var(--text-color);
    }
  }
}

.bg--theme-footer [data-theme] {
  background-color: var(--background-color);
  background-image: var(--background-gradient);
  color: var(--text-color);
}

.staycity--asterisk-bg {
  @include media(screen0, $direction: max) {
    position: relative;
    overflow: hidden;
    &:before {
      content: "";
      background-color: var(--background-color);
      background-image: url("../assets/icons/asterisk1.svg");
      background-repeat: no-repeat;
      background-position: top right;
      background-size: contain;
      position: absolute;
      top: convert-to-rem(65);
      right: convert-to-rem(-90);
      bottom: 0px;
      left: 0px;
      opacity: 0.04;
    }
    > div {
      position: relative;
    }
  }
}
