/* || WIDTH */
.w {
  &--max {
    width: max-content;
  }
  &--60px {
    width: convert-to-rem(60);
  }
  &--200px {
    width: convert-to-rem(200);
  }
  &--50 {
    width: 50%;
  }
  &--100 {
    width: 100%;
    &--max-290 {
      max-width: convert-to-rem(290);
    }
  }
  &--100-auto {
    width: 100%;
    @include media(screen1024) {
      width: auto;
    }
  }
  &--100-max {
    width: 100%;
    @include media(screen1024) {
      width: max-content;
    }
  }
  &--100-34 {
    width: 100%;
    @include media(screen1024) {
      width: 34%;
    }
  }
  &--100-40 {
    width: 100%;
    @include media(screen1024) {
      width: 40%;
    }
  }
  &--100-45 {
    width: 100%;
    @include media(screen1024) {
      width: 45%;
    }
  }
  &--100-50 {
    width: 100%;
    @include media(screen1024) {
      width: 50%;
    }
  }
  &--100-60 {
    width: 100%;
    @include media(screen1024) {
      width: 60%;
    }
  }
  &--100-66 {
    width: 100%;
    @include media(screen1024) {
      width: 66%;
    }
  }
  &--auto-50 {
    width: auto;
    @include media(screen1024) {
      width: 50%;
    }
  }
  &--footer-dropdowns {
    width: 100%;
    @include media(screen1024) {
      width: convert-to-rem(417);
    }
  }
  &--fit-content {
    width: fit-content;
    &--important {
      width: fit-content !important;
    }
  }
  &--apartment-summary {
    max-width: 104px;
    @include media(screen1024) {
      max-width: 190px;
    }
  }
}

/* || MIN-WIDTH */
.min-w {
  &--nav {
    min-width: unset;
    @include media(screen1024) {
      min-width: convert-to-rem(865);
    }
  }
  &--error-cta {
    min-width: unset;
    @include media(screen1024) {
      min-width: convert-to-rem(417);
    }
  }
}
/* || MAX-WIDTH */
.max-w {
  &--unset {
    max-width: unset;
  }
  &--desk-300 {
    @include media(screen1024) {
      max-width: convert-to-rem(300);
    }
  }
  &--update-guest {
    max-width: unset;
    @include media(screen1024) {
      max-width: convert-to-rem(765);
    }
  }
  &--login-register {
    max-width: unset;
    @include media(screen1024) {
      max-width: convert-to-rem(960);
    }
  }

  &--currency-overlay {
    max-width: unset;
    @include media(screen1024) {
      max-width: convert-to-rem(500);
    }
  }
  &--forgot-pass {
    max-width: unset;
    @include media(screen1024) {
      max-width: convert-to-rem(560);
    }
  }
  &--cancel {
    max-width: unset;
    @include media(screen1024) {
      max-width: convert-to-rem(530);
    }
  }
  &--my-account {
    max-width: unset;
    @include media(screen1024) {
      max-width: convert-to-rem(1280);
    }
  }
  &--promo-cards {
    max-width: 75vw;
    @include media(screen0) {
      max-width: unset;
    }
  }
  &--top-title-img {
    max-width: convert-to-rem(218);
    @include media(screen1024) {
      max-width: convert-to-rem(327);
    }
  }
  &--hotel-carousel-img {
    max-width: convert-to-rem(218);
    @include media(screen1024) {
      max-width: convert-to-rem(247);
    }
  }
  &--footer-dropdown {
    max-width: convert-to-rem(191);
  }
  &--footer-logo {
    max-width: unset;
    @include media(screen1024) {
      max-width: convert-to-rem(203);
    }
  }
  &--map-arrows {
    max-width: convert-to-rem(192);
  }
  &--cross-selling-logo {
    max-width: convert-to-rem(257);
    @include media(screen1024) {
      max-width: convert-to-rem(280);
    }
  }
  &--slim-promo-card {
    max-width: convert-to-rem(238);
    @include media(screen1024) {
      max-width: convert-to-rem(378);
    }
  }
  &--slim-promo-card-inner {
    max-width: convert-to-rem(198);
    @include media(screen1024) {
      max-width: convert-to-rem(294);
    }
  }
  &--blog-listing-header-img {
    max-width: convert-to-rem(390);
  }
  &--blog-list {
    max-width: convert-to-rem(1093);
  }
  &--reviews {
    max-width: 76vw;
    @include media(screen1024) {
      max-width: unset;
    }
  }
  &--reviews-overlay {
    max-width: unset;
    @include media(screen1024) {
      max-width: convert-to-rem(739);
    }
  }
  &--blog-img {
    max-width: convert-to-rem(530);
  }
  &--indent-feature {
    @include media(screen1024) {
      max-width: convert-to-rem(868);
    }
  }
  &--single-image-with-text-text {
    @include media(screen1024) {
      max-width: convert-to-rem(900);
    }
  }
}

/* || HEIGHT */
.h {
  &--40px {
    height: convert-to-rem(40);
  }
  &--600px {
    height: convert-to-rem(600);
  }
  &--50 {
    height: 50%;
  }
  &--95 {
    height: 95%;
  }
  &--100 {
    height: 100%;
  }
  &--100-70 {
    height: 100%;
    @include media(screen1024) {
      height: 70%;
    }
  }
  &--auto {
    height: auto;
  }
  &--auto-90 {
    height: auto;
    @include media(screen1024) {
      height: 90%;
    }
  }
  &--auto-100 {
    height: auto;
    @include media(screen1024) {
      height: 100%;
    }
  }
  &--header {
    height: 80vh;
  }
  &--city-header {
    height: 100%;
    @include media(screen1024) {
      height: 85vh;
    }
  }
  &--single-img-header {
    height: convert-to-rem(600);
    @include media(screen1024) {
      height: 100vh;
    }
  }
  &--single-img-header--reduced {
    height: convert-to-rem(600);
    @include media(screen1024) {
      height: 80vh;
    }
  }
  &--icon {
    height: $h-icon;
  }
}

/* || MIN-HEIGHT */
.min-h {
  &--480 {
    min-height: convert-to-rem(480);
  }
  &--100vh {
    min-height: 100vh;
  }
  &--header-bar {
    min-height: convert-to-rem(66);
    @include media(screen1024) {
      min-height: convert-to-rem(80);
    }
  }
  &--header {
    min-height: convert-to-rem(530);
  }
  &--single-img-header {
    min-height: convert-to-rem(600);
  }
  &--single-img-header--reduced {
    min-height: convert-to-rem(600);
  }
  &--city-header {
    min-height: unset;
    @include media(screen1024) {
      min-height: 100vh;
    }
  }
  &--link-grid {
    min-height: $min-h-link-grid-mob;
    @include media(screen1024) {
      min-height: $min-h-link-grid-desk;
    }
  }
  &--promo-card {
    min-height: convert-to-rem(235);
    @include media(screen1024) {
      min-height: convert-to-rem(315);
    }
  }
  &--error-page-img {
    min-height: convert-to-rem(170);
  }
}

/* || MAX-HEIGHT */
.max-h {
  &--0 {
    max-height: 0;
  }
  &--100vh {
    max-height: 100vh;
  }
  &--0-unset {
    max-height: 0;
    @include media(screen0) {
      max-height: unset;
    }
  }
  &--footer-logo {
    max-height: convert-to-rem(19);
    @include media(screen1024) {
      max-height: convert-to-rem(23);
    }
  }
  &--footer {
    max-height: unset;
    @include media(screen1024) {
      max-height: convert-to-rem(550);
    }
  }
  &--img-promo {
    max-height: unset;
    @include media(screen1024) {
      max-height: $max-h-img-promo;
    }
  }
  &--city-header-img {
    max-height: convert-to-rem(313);
  }
  &--blog-listing-header-img {
    max-height: convert-to-rem(398);
  }
  &--snackbar {
    max-height: convert-to-rem(100);
    @include media(screen1024) {
      max-height: convert-to-rem(75);
    }
  }
}
.h--70--mob {
  @include media(screen0, $direction: max) {
    height: convert-to-rem(70);
  }
}
