.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;

  &--desk-height-auto {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include media(screen1024) {
      height: auto;
    }
  }
}

.object-fit {
  &--contain {
    object-fit: contain;
  }
}

.aspect-ratio {
  &--1-1 {
    aspect-ratio: 1/1;
  }
  &--1_2-1 {
    aspect-ratio: 1.2/1;
  }
  &--1-1_5 {
    aspect-ratio: 1/1.5;
  }
  &--2-3 {
    aspect-ratio: 2/3;
  }
  &--2-1_3 {
    aspect-ratio: 2/1.3;
  }
  &--2_5-1 {
    aspect-ratio: 2.5/1;
  }
  &--0_67-1 {
    aspect-ratio: 0.67/1;
  }
  &--0_75-1 {
    aspect-ratio: 0.75/1;
  }
  &--16-9 {
    aspect-ratio: 16/9;
  }
}

.img-placeholder {
  background-color: rgba(var(--c-img-placeholder));

  &--desktop {
    background-color: none;
    @include media(screen1024) {
      background-color: rgba(var(--c-img-placeholder));
    }
  }
}

/* Dark shadow that darkens top part of an image. Makes text more readable */
.img-overlay {
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      180deg,
      #{rgba($c-black, 0.4)} 35.98%,
      transparent 87.43%
    );
    mix-blend-mode: multiply;
  }
}

.flip {
  &--x {
    transform: scaleX(-1);
  }
  &--y {
    transform: scaleY(-1);
  }
  &--xy {
    transform: scaleX(-1) scaleY(-1);
  }
}

.video-thumbnail {
  position: relative;
  cursor: pointer;

  &::before {
    content: "";
    background-color: #{rgba($c-black, 0.4)};
    width: convert-to-rem(122);
    height: convert-to-rem(122);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: $cta-transition;
  }
  &::after {
    content: "";
    mask-image: url("../assets/icons/play.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-40%) translateY(-50%);
    background-color: var(--c-video-thumbnail-icon);
    width: convert-to-rem(38);
    height: convert-to-rem(42);
  }

  &:hover {
    &::before {
      background-color: #{rgba($c-black, 0.8)};
    }
  }
}
