/* || TOP HEADER SECTION */

.header {
  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out,
    transform 0.5s ease-in-out, min-height 0.5s ease-in-out;
  &--inAccountSection {
    @include media(screen0, $direction: max) {
      > div {
        margin-left: 0;
        margin-right: 0;
        padding-right: 5vw;
        padding-left: 5vw;
      }
    }
  }
}

.header-hide {
  transform: translateY(-100%);
}

.header-logo {
  width: convert-to-rem($logo-w-mob);
  height: convert-to-rem($logo-h-mob);
  @include media(screen1024) {
    width: convert-to-rem($logo-w-desk);
    height: convert-to-rem($logo-h-desk);
  }
}

/* || DOUBLE IMAGE HEADER */
.double-header-scale {
  @include media(screen1024) {
    transform: scale(1.05);
  }
}
